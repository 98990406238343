import React from 'react'
import { Script } from "gatsby"
import { ConfirmationModalContextProvider } from './src/providers/AreYouSure'
import { AuthModalContextProvider } from './src/providers/Auth'
import { OperatorGalleryModalContextProvider } from './src/providers/OperatorGalleryModal'
import { OperatorGalleryFileModalContextProvider } from './src/providers/OperatorGalleryFileModal'
import { UpcomingToursBulkModalContextProvider } from './src/providers/UpcomingToursBulkModal'
import { TourBookingModalContextProvider } from './src/providers/TourBookingModal'
import { CustomRequestModalContextProvider } from './src/providers/CustomRequestModal'
// import { JqueryLoadingDiv } from './src/components/loaders/Loaders'
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'

// const $ = require("jquery")
// window.$ = $

// export const onInitialClientRender = () => {
//   window.onload = () => { 
//     $(document).ready(function () {
//       // setTimeout(() => {
//       //   $("#gatsby-loader").css({display:"none"})
//       // }, 1000)
//       // setTimeout(() => {
//       //   $("#gatsby-loader").css({display:"none"})
//       // }, 5000)
//       console.log('Window on load!!!')
//     })
//   }
// }

// export const onClientEntry = () => {
//   $(document).ready(function () {
//     console.log("The answer is don't think about it!")
//     // setTimeout(() => {
//     //   $("#gatsby-loader").css({display:"none"})
//     // }, 1000)
    
//     // setTimeout(() => {
//     //   $("#gatsby-loader").css({display:"none"})
//     // }, 5000)
//   });
// }

// TODO: Fix scrolling to position
// shouldUpdateScroll Function
// Source


export const wrapRootElement = ({ element }) => (
  <>
  <Script src="https://accounts.google.com/gsi/client" async />
  <Script src="https://www.google.com/recaptcha/enterprise.js?render=6Lc6smMpAAAAAHNYtffUPG1UPsXiLRZ7Eya7NWV1" async />
  
  <AuthModalContextProvider>
    <ConfirmationModalContextProvider>
      <CustomRequestModalContextProvider>
        <OperatorGalleryFileModalContextProvider>
          <OperatorGalleryModalContextProvider>
            <UpcomingToursBulkModalContextProvider>
              <TourBookingModalContextProvider>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                    {element}
                    {/* <div id="gatsby-loader"
                      style={{
                      width: "100px",
                      height: "100px",
                      display: "block",
                      position: "fixed",
                      top: "40px",
                      left: "50%",
                      margin: "0px auto auto -50px",
                      zIndex: 10000
                    }}>
                      <JqueryLoadingDiv />
                    </div> */}
                </LocalizationProvider>
              </TourBookingModalContextProvider>
            </UpcomingToursBulkModalContextProvider>
          </OperatorGalleryModalContextProvider>
        </OperatorGalleryFileModalContextProvider>
      </CustomRequestModalContextProvider>
    </ConfirmationModalContextProvider>
  </AuthModalContextProvider>
  </>
)