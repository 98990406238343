import { triggerWindowScroll } from '../utils/responsive'
import React, { useState, useRef, useContext} from 'react'
import Modal from 'react-bootstrap/Modal'
export const UpcomingToursBulkModalContext = React.createContext({})
export const useUpcomingToursBulkModalContext = () => useContext(UpcomingToursBulkModalContext) as {showView: (message: string, value: "" | "number") => Promise<boolean | number>}

export const UpcomingToursBulkModalContextProvider = (props: any) => {
  const [showModal, setShowModal] = useState(false)
  const [message, setMessage] = useState('')
  const [inputType, setInputType] = useState<"" | "number">('')
  const resolver = useRef<(value: unknown) => void>()

  const [value, setValue] = useState<number | null>(0)

  const handleShow = (message: string, val: "" | "number") => {
    setMessage(message)
    setInputType(val)
    setShowModal(true)
    setValue(null)

    return new Promise(function (resolve) {
      resolver.current = resolve
    })
  }

  const handleOk = () => {
    resolver.current && resolver.current(inputType === "number" ? value : true)
    setShowModal(false)
  }

  const handleCancel = () => {
    resolver.current && resolver.current(false)
    setShowModal(false)
  }

  return (
    <UpcomingToursBulkModalContext.Provider value={{showView: handleShow}}>
      {props.children}

      <Modal show={showModal} onHide={() => {setShowModal(false); triggerWindowScroll()}} centered dialogClassName="modal-xs modal-dialog--tours-bulk-actions">
        <Modal.Body>
            <p>{message}</p>
            {inputType === "number" && <input type="number" onChange={(e) => setValue(parseFloat(e.target.value))} />}
        </Modal.Body>

        <Modal.Footer>
          <button className="btn btn-default" onClick={handleCancel}>Cancel</button>
          <button className="btn btn-primary" onClick={handleOk}>OK</button>
        </Modal.Footer>
      </Modal>

    </UpcomingToursBulkModalContext.Provider>
  )
}