export enum TourType {
    'exploration',
    'dining',
    'watersports',
    'fishing', 
    'party',
    'multiday',
    'special',
}

export const tourTypeKeys = Object.values(TourType).filter(v => !Number.isInteger(v)) as string[]

export const getTourTypeTranslation = (b: string) => {
    switch (String(b)) {
        case 'exploration':
            return 'Exploration'
        case 'dining':
            return 'Dining'
        case 'watersports':
            return 'Water sports'
        case 'fishing':
            return 'Fishing'
        case 'party':
            return 'Party'
        case 'multiday':
            return 'Multi day'
        case 'special':
            return 'Special'
        default:
            return ''
    }
}