/* tslint:disable */
// generated by typescript-json-validator
// import {inspect} from 'util';
import Ajv from 'ajv';
import ILoginData from '../types/ILoginData';
export const ajv = new Ajv({"allErrors":true,"coerceTypes":false,"format":"full","nullable":true,"unicode":true,"uniqueItems":true,"useDefaults":true});

import schema from 'ajv/lib/refs/json-schema-draft-06.json'
ajv.addMetaSchema(schema);
// ajv.addMetaSchema(require('ajv/lib/refs/json-schema-draft-06.json'));

// export {IType};

export const ITypeResponseSchema = JSON.parse(`{"defaultProperties":[],"properties":{"captcha":{"type":"string"},"email":{"format":"email","type":"string"},"password":{"minLength":8,"type":"string"}},"required":["email","password"],"type":"object","$schema":"http://json-schema.org/draft-07/schema#"}`);
export type ValidateFunction<T> = ((data: unknown) => data is T) & Pick<Ajv.ValidateFunction, 'errors'>
export const isIAPIResponse = ajv.compile(ITypeResponseSchema) as ValidateFunction<ILoginData>;


// {
//   keyword: 'minLength',
//   dataPath: '.password',
//   schemaPath: '#/properties/password/minLength',
//   params: { limit: 10 },
//   message: 'should NOT be shorter than 10 characters'
// }
  
export default function validate(value: unknown): Ajv.ValidateFunction | null {

  if (!isIAPIResponse(value)) {
    return isIAPIResponse
  }

  return null
  
  // throw new Error(
  //   ajv.errorsText('Property' + isIAPIResponse.errors!.filter((e: any) => e.keyword !== 'if'), {dataVar: ''}),
  // );
}
