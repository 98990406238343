import React, { useCallback, useState } from "react"
import { Formik, Form, Field, ErrorMessage } from 'formik'
import { LoadingCover } from '../../loaders'
import IFormParams from "../../../types/IFormParams"
import Phone from "../../form/phone/Phone"
import IRegisterData from '../../../shared/types/IRegisterData'
import validateILoginData from '../../../shared/validators/ILoginData.validator'
import getValidationErrors from "../../../utils/getValidationErrors"
import { resolveFormSubmit } from "../../../utils/form"
import _ from 'lodash'

const defaultParams:IRegisterData = { 
  name: '', 
  surname: '',
  phone: '',
  email: '',
  address: '',
  password: '',
}

export default function RegisterForm(args: IFormParams) {
 return (
  <Formik
       initialValues={args.params || _.cloneDeep(defaultParams)}
       validate={values => {
        const errors = getValidationErrors(values, validateILoginData)
        if(args.options?.hidePassword && errors.password) delete errors.password

        return errors
       }}
       onSubmit={async (values, { setSubmitting }) => {
          await resolveFormSubmit(values, args)
          setSubmitting(false)
       }}
     >
       {({ isSubmitting, isValid, values }) => (
          <Form className="form">
            <div className="form__row">
              <label>Name<ErrorMessage className="form__row__error" name="name" component="i" /></label>
              <Field type="text" name="name" />
            </div>

            <div className="form__row">
              <label>Surname<ErrorMessage className="form__row__error" name="surname" component="i" /></label>
              <Field type="text" name="surname" />
            </div>

            <div className="form__row">
              <label>Phone number<ErrorMessage className="form__row__error" name="phone" component="i" /></label>
              <Field
                name="phone"
                component={Phone}
              />
            </div>

            <div className="form__row">
              <label>Address<ErrorMessage className="form__row__error" name="address" component="i" /></label>
              <Field type="text" name="address" />
            </div>

            <div className="form__row">
              <label>Email <ErrorMessage className="form__row__error" name="email" component="i" /></label>
              <Field type="email" name="email" />
            </div>

            {!args.options?.hidePassword && <>
            <div className="form__row">
              <label>Password <ErrorMessage className="form__row__error" name="password" component="i" /></label>
              <Field type="password" name="password" />
            </div>
            </>}

            <button className="button" type="submit" aria-label="Register">
              {args.options.submitBtnTxt || "Submit"}
            </button>
            
            <LoadingCover loading={isSubmitting} />
          </Form>
       )}
     </Formik>
 )
}