import moment from "moment"


// Compensates for current timezone since we are calculating everything in absolute terms
// where time is set absolute to the time zone where trip is in
// and date is unix timestamp
export const defaultYear = 2000

export const getAbsoluteNow = () => {
    const now = moment()
    const m = now.month()
    const d = now.date()
    const h = now.hour()
    const min = now.minute()
    const unixNow = moment().utc(false).month(m).date(d).hour(h).minute(min)
    // console.log('unixNow', unixNow)
    return unixNow.unix()
}


export const getAbsoluteMonthDay = (month: number, day: number, endOfDay=false) => {
  const d = moment.unix(getAbsoluteNow()).utc(false).year(defaultYear).month(month).date(day+1).set({hour:endOfDay ? 23 : 0, minute:endOfDay ? 59 : 0, second: endOfDay ? 59 : 0, millisecond : 0})
  return d.unix()
}

export const getAbsoluteHourMinute = (hour: number, minute: number) => {
  const d = moment.unix(getAbsoluteNow()).utc(false).year(defaultYear).month(0).date(1).set({hour, minute, second: 0, millisecond:0})
  return d.unix()
}

// export const getAbsoluteDateTime = (month: number, day: number, hour: number, minute: number) => {
//   const d = moment.unix(getAbsoluteNow()).utc(false).year(defaultYear).month(month).date(day+1).set({hour,minute,second:0,millisecond:0})
//   return d.unix()
// }

// Pretty output
export const getDayOfWeekFromUnix = (unix: number, utc=false) => {
  let day = moment.unix(unix).utc(utc).day() // For some weird reason, sunday is 0, monday 1, tue 2..
  if (day === 0) day = 7
  return day -1
}

export const getDateFromUnix = (date: number, utc=false) => {
    return moment.unix(date).utc(utc).format('ddd, DD.MM')
}

export const getDateTimeFromUnix = (date: number, utc=false) => {
  return moment.unix(date).utc(utc).format('ddd, DD.MM HH:mm')
}

export const getSingleTimeFromUnix = (date: number, utc=false) => {
  return `${moment.unix(date).utc(utc).format('HH:mm')}`
}

export const getTimeFromUnix = (dateStart: number, dateEnd: number) => {
  return `${getSingleTimeFromUnix(dateStart)}-${getSingleTimeFromUnix(dateEnd)}`
}
