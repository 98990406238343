import React, { useState, useContext } from 'react'
import Modal from 'react-bootstrap/Modal'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import { LoadingCover } from '../components/loaders'
import IFormParams from "../types/IFormParams"
import { resolveFormSubmit } from "../utils/form"
import { scrollToFirstError } from "../services/form"
import IAPIFrontResponse from "../types/IAPIFrontResponse"
import { FormResponseMsgSuccess, FormResponseMsgError } from "../components/form/formResponse/FormResponse"

import { triggerWindowScroll } from '../utils/responsive'
import { updateOperatorFileDescription } from '..//services/api'


export const OperatorGalleryFileModalContext = React.createContext({})
export const useOperatorGalleryFileModalContext = () => useContext(OperatorGalleryFileModalContext) as {showGallery: (
  data: any, 
  cb: (data: any) => void,
  ) => Promise<void>
}


export default function OperatorGalleryImageForm(args: IFormParams) {
  return (
  <Formik
      initialValues={args.params || null}
      validate={async (values) => {}}
      onSubmit={async (values, { setSubmitting }) => {
        await resolveFormSubmit({
          id: values.id,
          name: values.name,
          description: values.description
        }, args)
        setSubmitting(false)
      }}
    >
      {({ isSubmitting, isValid, values }) => (
        <Form className="form">
          <div className="form__row">
            <label>Image title<ErrorMessage className="form__row__error" name="name" component="i" /></label>
            <Field type="text" name="name" />
          </div>

          <div className="form__row">
            <label>Image Description<ErrorMessage className="form__row__error" name="description" component="i" /></label>
            <Field
              className="form__textarea"
              name="description"
              component={'textarea'}
              rows="5"
              placeholder="Add a description"
              style={{height: "80px"}}
              isMulti={true}
            />
          </div>

          <button className="button" type="submit" onClick={() => setTimeout(scrollToFirstError, 200)}>
            {args.submitBtnText || "Submit"}
          </button>
          
          <LoadingCover loading={isSubmitting} />
        </Form>
      )}
    </Formik>
 )
}


export const OperatorGalleryFileModalContextProvider = (props: any) => {
  const [showGalleryFileModal, setShowGalleryFileModal] = useState(false)
  const [data, setData] = useState<number[]>([])
  const [callback, setCallback] = useState<((data: any) => void) | null>(null)

  const handleShow = (d: any, cb: (d: any) => void) => {
    setData(d)
    setCallback(() => cb)
    setShowGalleryFileModal(true)
  }

  const [successMsg, setSuccessMsg] = useState<string | null>(null)
  const [errorMsg, setErrorMsg] = useState<string | null>(null)

  const onSubmit = async (newData: any): Promise<IAPIFrontResponse> => {
    setSuccessMsg(null)
    setErrorMsg(null)
    const res = await updateOperatorFileDescription(newData)
    console.log(data)
    return res
  }

  const success = async (data: any, resp: IAPIFrontResponse) => {
    setSuccessMsg(resp.message || "Success")
    callback && callback(data)
    setTimeout(() => setShowGalleryFileModal(false), 2000)
  }

  const error = async (data: any, resp: IAPIFrontResponse) => {
    setErrorMsg(resp.message || "Error updating image")
  }

  return (
    <OperatorGalleryFileModalContext.Provider value={{showGallery: handleShow}}>
      {props.children}

      <Modal show={showGalleryFileModal} onHide={() => { setShowGalleryFileModal(false); triggerWindowScroll()}} centered dialogClassName="modal-small modal-dialog">
        <Modal.Body>
          <i className="modal-body__close" onClick={() => setShowGalleryFileModal(false)}>x</i>
          <FormResponseMsgSuccess message={successMsg} clearParent={setSuccessMsg}/>
          <FormResponseMsgError message={errorMsg} clearParent={setErrorMsg} timeout={10000} />

          <div className="form-wrapper">
            <OperatorGalleryImageForm params={data} onSubmit={onSubmit} onSuccess={success} onError={error} />
          </div>
        </Modal.Body>
      </Modal>
    </OperatorGalleryFileModalContext.Provider>
  )
}
