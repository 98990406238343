import React, { useState, useContext } from 'react'
import Modal from 'react-bootstrap/Modal'
import OperatorGallery from "../components/operator/operatorGallery/OperatorGallery"
import { triggerWindowScroll } from '../utils/responsive'
export const OperatorGalleryModalContext = React.createContext({})
export const useOperatorGalleryModalContext = () => useContext(OperatorGalleryModalContext) as {showGallery: (
  selected: number[], 
  cb: (ids: number[]) => void,
  limit: number | undefined
  ) => Promise<void>
}


export const OperatorGalleryModalContextProvider = (props: any) => {
  const [showGalleryModal, setShowGalleryModal] = useState(false)
  const [selected, setSelected] = useState<number[]>([])
  const [callback, setCallback] = useState<((ids: number[]) => void) | null>(null)
  const [limit, setLimit] = useState<number | undefined>(undefined)

  const handleShow = (ids: number[], cb: (ids: number[]) => void, limit: number | undefined) => {
    setSelected(ids)
    setCallback(() => cb)
    setLimit(limit)
    setShowGalleryModal(true)
  }

  const onSelected = (ids: number[], cb: ((ids: number[]) => void) | null) => {
    cb && cb(ids)
    setSelected([])
    setCallback(null)
    setShowGalleryModal(false)
  }

  return (
    <OperatorGalleryModalContext.Provider value={{showGallery: handleShow}}>
      {props.children}

      <Modal show={showGalleryModal} onHide={() => { setShowGalleryModal(false); triggerWindowScroll()}} centered dialogClassName="modal-xl modal-dialog">
        <Modal.Body>
          <i className="modal-body__close" onClick={() => setShowGalleryModal(false)}>x</i>
          <OperatorGallery selected={selected}  limit={limit} useSelected={(ids: number[]) => { onSelected(ids, callback) }}/>
        </Modal.Body>
      </Modal>
    </OperatorGalleryModalContext.Provider>
  )
}
