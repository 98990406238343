import { ValidateFunctionResponse } from '../shared/validatorTemplate'
import setObjectProperty from './setObjectProperty'

const getValidationErrors = (values: any, validator: (data: any) => ValidateFunctionResponse | null): any => {
  const errors: any = {}
  const validationErrors = validator(values)

  // console.log('validationErrors', validationErrors, validationErrors.errors)
    
  if(validationErrors && validationErrors.errors) {
    for(const e of validationErrors.errors) {
      if (e.dataPath) {
        setObjectProperty(errors, e.dataPath || e.params.missingProperty, `Value ${e.message}`)
        continue
      }

      if (!e.dataPath && e.params.missingProperty) {
        setObjectProperty(errors, e.params.missingProperty, `Value required`)
        continue
      }

      console.log('Unknown validation error', e)
      setObjectProperty(errors, e.dataPath, `Value ${e.message}`)
    }
  }

  return errors
}

export default getValidationErrors