import { FieldProps } from "formik"
import React from "react"
import Editor from 'react-simple-wysiwyg'
import "./wysiwyg.scss"


interface CustomSelectProps extends FieldProps {
    className?: string
    placeholder?: string
  }

export const Wysiwyg = ({
    className,
    placeholder,
    field,
    form,
  }: CustomSelectProps) => {
    const onChange = (value: any) => {
      form.setFieldValue(
        field.name,
        value
      )
    }

    return (
        <Editor value={field.value || ""} onChange={(e: any) => onChange(e.target.value)} />
    )
  }
  
  export default Wysiwyg