import React, { useCallback, useState } from "react"
import { Formik, Form, Field, ErrorMessage } from 'formik'
import { LoadingCover } from '../../loaders'
import IFormParams from "../../../types/IFormParams"
import validateILoginData from '../../../shared/validators/ILoginData.validator'
import getValidationErrors from "../../../utils/getValidationErrors"
import { resolveFormSubmit } from "../../../utils/form"
import _ from 'lodash'

const defaultParams:any = { 
  email: '', 
}

export default function ForgotPasswordForm(args: IFormParams) {
 return (
  <Formik
       initialValues={args.params || _.cloneDeep(defaultParams)}
       validate={values => {
        // return getValidationErrors(values, validateILoginData)
        return {}
       }}
       onSubmit={async (values, { setSubmitting }) => {
          await resolveFormSubmit(values, args)
          setSubmitting(false)
       }}
     >
       {({ isSubmitting, isValid }) => (
          <Form className="form">
            <div className="form__row" style={{marginBottom: 0}}>
              <label>Email <ErrorMessage className="form__row__error" name="email" component="i" /></label>
              <Field type="email" name="email" />
            </div>
            <p className="form__explanation">
              A password recovery link will be sent to your email. After clicking on that link you will be redirected to a view that will 
              allow you to reset your password.
            </p>

            <button className="button" type="submit" aria-label="Login">
              {args.options.submitBtnTxt || "Send Recovery Link"}
            </button>
            
            <LoadingCover loading={isSubmitting} />
          </Form>
       )}
     </Formik>
 )
}
