// https://www.npmjs.com/package/react-drag-drop-files
import React, { useCallback, useEffect, useState } from "react"
import { FileUploader } from "react-drag-drop-files"
import { LoadingCover } from '../../loaders'
import ImageCard from "../../imageCard/ImageCard"
import { useOperatorGalleryFileModalContext } from "../../../providers/OperatorGalleryFileModal"
import * as api from '../../../services/api'
import './operator-gallery.scss'


const fileTypes = ["JPEG", "JPG", "PNG", "GIF", "AVI", "MPEG"]


const GalleryFile = (props: any) => {
  const fileContext = useOperatorGalleryFileModalContext()
  return <div 
    className={"card gallery-file " + (props.selected ? 'gallery-file--selected' : '')}
    >
      <div className="card__image" onClick={() => props.handleSelect(props.id)}>
        <ImageCard images={[props]} windowWidthPercent={30} />
        {(!props.name || !props.description) && <i className="icon icon--unpublished">
          <p className="icon__popup icon__popup--bottom-left">Please add description</p>
        </i>}

        {props.selected &&  <div className="gallery-file__selected-icon"><i className="icon icon--check"></i></div>}
      </div>
     
      <div className="card__content">
        <h3>{props.name}</h3>
      </div>
      <a className="button button--minimalistic button--small" onClick={(e) => {e.preventDefault(); fileContext.showGallery(props, props.updateFileDescription)}} >Edit description</a> 
  </div>
}

export default function OperatorGallery(props: any) {
    const [selected, setSelected] = useState<number[]>(props.selected || [])
    const [isLoading, setIsLoading] = useState(false)
    const [uploadingImageNr, setUploadingImageNr] = useState<string | null>(null)
    const [files, setFiles] = useState<any[]>([])

    useEffect(() => {
        const req = async () => {
            setIsLoading(true)
            const res = await api.getOperatorFiles()
            setFiles(res.data)
            setIsLoading(false)
        }

        req()
    }, [])

    const handleSelect = (id: number) => {
        const index = selected.indexOf(id)

        let res
        if (index === -1) {
          res = [id, ...selected]
        } else {
            selected.splice(index,1)
            res = [...selected]
        }


        if(props.limit && res.length > props.limit) {
          res.splice(res.length - props.limit, props.limit)
        }

        setSelected(res)
    }

    const updateFileDescription = (data: any) => {
      for (const file of files) {
        if(file.id === data.id) {
          file.name = data.name
          file.description = data.description
          setFiles([...files])
          break
        }
      }
    }

    const handleChange = async (newFiles: any) => {
        setIsLoading(true)

        const newFilesRes: any[] = []
        for(let i=0; i< newFiles.length; i++) {
          const formData = new FormData()
          setUploadingImageNr(`${i+1}/${newFiles.length}`)
          formData.append('images', newFiles[i])
          const res = await api.uploadOperatorFiles(formData)
          newFilesRes.push(...res.data)
        }

        setFiles([...newFilesRes, ...files])
        setUploadingImageNr(null)

        // const formData = new FormData()
        // for(let i=0; i< newFiles.length; i++) {
        //     formData.append('images', newFiles[i])
        // }
        // const res = await api.uploadOperatorFiles(formData)

        // setFiles([...res.data, ...files])

        setIsLoading(false)
    }

    const deleteSelected = async() => {
        setIsLoading(true)
        const res = await api.deleteOperatorFiles(selected)
        setFiles(files.filter((f) => res.data.indexOf(f.id) === -1))
        setSelected([])
        setIsLoading(false)
    }

    return (
      <div className="operator-gallery floating-buttons">
        <FileUploader
          multiple={true}
          handleChange={handleChange}
          name="file"
          types={fileTypes}
          maxSize={50}
          minSize={0}
          label={'Upload or drop files here'}
        />
        {uploadingImageNr !== null && <p>{`Uploading image ${uploadingImageNr} `}</p>}

        {props.limit && <p className="operator-gallery__max-selected">Max files selected: {props.limit}</p>}

        <div className="operator-gallery__files cards-list">
            {files.map((file: any, i:number) => <GalleryFile key={i} selected={selected.indexOf(file.id) !== -1} handleSelect={handleSelect} updateFileDescription={updateFileDescription} {...file} />)}
        </div>

        {(selected.length > 0 || props.useSelected) && <div className="operator-gallery__buttons-wrapper floating-buttons__buttons">
            {!props.useSelected && selected.length > 0  && <div className="button button-big" onClick={deleteSelected}><h2>Delete</h2></div>}
            {props.useSelected && <div className="button button-big" onClick={() => { props.useSelected(selected)}}><h2>Select</h2></div>}
        </div>}

        <LoadingCover loading={isLoading} />
      </div>
    )
}
