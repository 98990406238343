import IUserJWTData from "@src/shared/types/IUserJWTData"
import { isBrowser } from "./browser"
import moment from 'moment'
import { favourites_ls_key } from './favourites'

const ls_key = "tour_boats_jwt"

export const getToken = (): IUserJWTData | null => {
  const token = isBrowser() && window.localStorage.getItem(ls_key)
  return isBrowser() && token && token !== 'undefined' ? JSON.parse(token) : null
}

export const setToken = (token: IUserJWTData) => {
  isBrowser() && window.localStorage.setItem(ls_key, JSON.stringify(token))
}

export const setLocalstorage = (key: string, value: string) => {
  isBrowser() && window.localStorage.setItem(key, value)
}

export const getLocalstorage = (key: string) => {
  return isBrowser() && window.localStorage.getItem(key)
}


export const isLoggedIn = (role:null|string = null):boolean => {
  const token = getToken()
  if (!token) return false

  const now = moment().unix()
  if (now > token.expiresOn) return false

  if (role && role != token.user.role) return false

  return true
}

export const logout = ()=> {
  isBrowser() && window.localStorage.removeItem(ls_key)
  isBrowser() && window.localStorage.removeItem(favourites_ls_key)
}