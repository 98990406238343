import * as React from "react"
import { createRef, useEffect, useRef, useState } from "react"

import './stars.scss'


const allStars = new Array(5).fill(1)

const Stars = ({stars, votes}: {stars: number, votes: number}) => {
  return <div className="stars">
    {allStars.map((s: any, i: number) => <div key={i} className={'stars__star ' + (i < stars ? 'stars__star--active' : '')}></div>)}
    {votes > 0 && <small>({votes})</small>}
  </div>
}


export const StarsSelect = ({value, onSelect}: {value: number, onSelect: (i: number) => void}) => {
  return <div className="stars stars--select">
    {allStars.map((s: any, i: number) => {
      return <div 
        key={i} 
        className={'stars__star ' + (i+1 <= value ? 'stars__star--active' : '')} 
        onClick={() => onSelect(i+1)}
        ></div>
    })}
  </div>
}

export default Stars