import React, { useState, useContext, useEffect } from 'react'
import Modal from 'react-bootstrap/Modal'
import { navigate } from '@reach/router'
import { updateQueryStringParameter } from '../services/browser'
import { getToken, isLoggedIn, logout } from '../services/auth'
import { LoginWidget } from '../components/auth/loginWidget/loginWidget'
import IUserData from "../shared/types/IUserData"
export const AuthModalContext = React.createContext({})
export const useAuthModalContext = () => useContext(AuthModalContext) as {
  showAuth: (m:string|null, v:string|null, onClose:undefined | (() => void)) => Promise<void>,
  isLoggedIn: (role:null|string) => boolean,
  getLoggedInUserDetails: () => IUserData | null
  logout: () => void,
}

import './auth.scss'

export const AuthModalContextProvider = (props: any) => {
  const [showAuthModal, setShowAuthModal] = useState(false)
  const [message, setMessage] = useState('')
  const [onCloseCallback, setOnCloseCallback] = useState<any>(null)
  const [callback, setCallback] = useState('')
  const [view, setView] = useState('login')

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)

    if(urlParams.get('showAuth') === 'true' && !showAuthModal) {
      window.history.pushState(null,"", updateQueryStringParameter(window.location.href, 'showAuth', 'false'))
      const status = urlParams.get('status')
      if(status == '401') setMessage('Your login has expired. Please log in again')

      const cb = urlParams.get('callback')
      if(cb) setCallback(cb)

      setTimeout(() => {
        setShowAuthModal(true)
      }, 1000)
    }
  }, [window.location.search])

  const handleShow = (m: string, v='login', onClose: (() => void) | undefined) => {
    setMessage(m)
    setView(v)
    setOnCloseCallback(() => onClose)
    setShowAuthModal(true)
  }

  const handleOk = () => {
    setShowAuthModal(false)
  }

  const handleCancel = () => {
    setShowAuthModal(false)
  }

  const handleLogout = () => {
    logout()
    navigate("/")
  }

  const handleGetLoggedInUserDetails = () => {
    const userData = getToken()
    if (!userData || ! userData.user) return null
    userData.user.image_url && (userData.user.image_url = decodeURI(userData.user.image_url))
    return userData.user
  }

  return (
    <AuthModalContext.Provider value={{showAuth: handleShow, getLoggedInUserDetails: handleGetLoggedInUserDetails, isLoggedIn: isLoggedIn, logout: handleLogout}}>
      {props.children}

      <Modal show={showAuthModal} onHide={() => setShowAuthModal(false)} centered dialogClassName="modal-xs modal-dialog--are-you-sure">
        <Modal.Body>
          <i className="modal-body__close" onClick={() => { setShowAuthModal(false); onCloseCallback && onCloseCallback(); }}>x</i>
          <LoginWidget view={view} message={message} callback={callback} />
        </Modal.Body>
      </Modal>

    </AuthModalContext.Provider>
  )
}
