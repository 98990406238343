import React, { useEffect, useState } from 'react'
import './form-response.scss'

export function FormResponseMsg({message, success, clearParent=undefined, timeout=5000}: {message: string | null, success: boolean, clearParent?: (msg: string | null) => void, timeout?: number}): JSX.Element {
  const [msg, setMsg] = useState<string | null>(null)
  
  useEffect(() => {
    setMsg(message)

    const timer = setTimeout(() => {
      setMsg(null)
      clearParent && clearParent(null)
    }, timeout)

    return () => clearTimeout(timer);
  }, [message])

  if (msg) {
    return <div className={"form-response " + (success ? "form-response--success" : "form-response--error")}>
    {msg}
  </div>
  }

  return <></>
}

export function FormResponseMsgSuccess({message, clearParent=undefined, timeout=5000}: {message: string | null, clearParent?: (msg: string | null) => void, timeout?: number}): JSX.Element {
  return <FormResponseMsg message={message} success={true} clearParent={clearParent} timeout={timeout} />
}

export function FormResponseMsgError({message, clearParent=undefined, timeout=5000}: {message: string | null, clearParent?: (msg: string | null) => void, timeout?: number}): JSX.Element {
  return <FormResponseMsg message={message} success={false} clearParent={clearParent} timeout={timeout} />
}
