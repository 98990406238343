import fetch from 'isomorphic-fetch'
import config from './config'
import ILoginData from '@src/shared/types/ILoginData'
import IAPIResponse from '../shared/types/IAPIResponse'
import IAPIFrontResponse from '../types/IAPIFrontResponse'
import IUpsertBoatData from '../shared/types/IUpsertBoatData'
import IUpsertTourData from '../shared/types/IUpsertTourData'
import IUpsertOperatorData from '../shared/types/IUpsertOperatorData'
import ITourBookingData from '../shared/types/ITourBookingData'
import ITourConfirmData from '../shared/types/ITourConfirmData'
import ITourBookingOffsiteData from '@src/shared/types/ITourBookingOffsiteData'
import ITourBookingContainer from '@src/shared/types/ITourBookingContainer'
import { getToken } from './auth'


const fetchApi = async (method:string, path:string, data:any = null, formData:any = null, queryParams: any=null) => {
  let uri = `${config.backendUrl}${path}`
  const options: RequestInit = {
    headers: {
      'Content-Type': 'application/json',
    },
    method: method,
  }

  // Add token to header
  const token = getToken()
  if(token && token.accessToken ) {
    (options.headers as any)['Authorization'] = `Bearer ${token.accessToken}`
  }

  // Add data to request
  if(data) {
    options.body = JSON.stringify(data)
  }

  if(formData) {
    delete (options.headers as any)['Content-Type']
    options.body = formData
  }

  if(queryParams) {
    const p = []
    for(const key in queryParams) {
      p.push(`${key}=${queryParams[key]}`)
    }

    uri = `${uri}?${p.join('&')}`
  }
  
  const res = await fetch(uri, options)

  if(res.status === 401 && window) {
    window.location.assign(`/?showAuth=true&status=401&callback=${encodeURIComponent(window.location.href)}`)
  }

  return await formatResponse(res)
}


const formatResponse = async (res: Response): Promise<IAPIFrontResponse> => {
  const data: IAPIResponse = await res.json()

  return {
    status_code: res.status,
    ...data
  }
}

// TOURS LIST 
export type GetToursParams = {
  operatorId?: number
  skip?: number
  take?: number
  minResults?: number
  filters: string[]
}

export const getTours = async(params: GetToursParams|null): Promise<IAPIFrontResponse> => {
  return await fetchApi('GET', '/site/tours', null, null, params)
}
export const getFavoriteTours = async(): Promise<IAPIFrontResponse> => {
  return await fetchApi('GET', '/site/tours/favorite')
}
export const getTour = async(id?: string, operatorSlug?: string, tourSlug?: string): Promise<IAPIFrontResponse> => {
  let res
  if(id !== undefined) {
    res = await fetchApi('GET', `/site/tours/${id}`)
    if(res.status_code !== 400) return res
    if(operatorSlug === undefined || tourSlug === undefined) return res
  }
  
  const slug = `slug__${operatorSlug}__${tourSlug}`
  res = await fetchApi('GET', `/site/tours/${slug}`)
  return res
}
export const getTourAvailability = async(id: number, after:number|null=null, count:number|null=null): Promise<IAPIFrontResponse> => {
  const queryParams:any = {}
  if(after) queryParams.after = after
  if(count) queryParams.count = count

  return await fetchApi('GET', `/site/tours/${id}/availability`, null, null, queryParams)
}
export const getTourBookingConfirm = async(id: number, startTime: number): Promise<IAPIFrontResponse> => {
  const queryParams:any = {}
  if(startTime) queryParams.startTime = startTime

  return await fetchApi('GET', `/site/tours/${id}/booking-confirm`, null, null, queryParams)
}

export const getTourBookingCheckoutData = async(data: {tourConfirmData: ITourConfirmData, bookingData: ITourBookingData} ): Promise<IAPIFrontResponse> => {
  return await fetchApi('POST', `/site/tours/${data.bookingData.tourId}/booking-checkout-data`, data)
}

// BOOKINGS
export const getBookingPaymentIntentCheck = async(paymentIntentId: string): Promise<IAPIFrontResponse> => {
  const queryParams:any = {}
  if(paymentIntentId) queryParams.paymentIntentId = paymentIntentId
  return await fetchApi('GET', `/site/bookings/payment-intent-check`, null, null, queryParams)
}

export const getBooking = async(id: number): Promise<IAPIFrontResponse> => {
  return await fetchApi('GET', `/site/bookings/${id}`)
}

export const getBookings = async(): Promise<IAPIFrontResponse> => {
  return await fetchApi('GET', `/site/bookings`)
}


// COMMENTS
export const getCommentHash = async(hash: string, rating: string|null = null): Promise<IAPIFrontResponse> => {
  const queryParams:any = {}
  if(hash) queryParams.hash = hash
  if(rating) queryParams.rating = rating
  return await fetchApi('GET', `/site/comments/get-hash`, null, null, queryParams)
}

export const postCommentHash = async(data: any): Promise<IAPIFrontResponse> => {
  return await fetchApi('POST', `/site/comments/post-hash`, data)
}
export const updateCommentHash = async(data: any): Promise<IAPIFrontResponse> => {
  return await fetchApi('PUT', `/site/comments/put-hash`, data)
}


export const getComment = async(id: number): Promise<IAPIFrontResponse> => {
  return await fetchApi('GET', `/site/comments/${id}`)
}
export const postComment = async(data: any): Promise<IAPIFrontResponse> => {
  return await fetchApi('POST', `/site/comments`, data)
}
export const updateComment = async(data: any): Promise<IAPIFrontResponse> => {
  return await fetchApi('PUT', `/site/comments/${data.id}`, data)
}
export const getTourUserComment = async(tourId: number): Promise<IAPIFrontResponse> => {
  return await fetchApi('GET', `/site/comments/tour-user/${tourId}`)
}
export const getTourComments = async(tourId: number, page=0): Promise<IAPIFrontResponse> => {
  const queryParams = {} as any
  if(page) queryParams.page = page
  return await fetchApi('GET', `/site/comments/tour/${tourId}`, null, null, queryParams)
}
export const getOperatorComments = async(operatorId: number, page=0): Promise<IAPIFrontResponse> => {
  const queryParams = {} as any
  if(page) queryParams.page = page
  return await fetchApi('GET', `/site/comments/operator/${operatorId}`, null, null, queryParams)
}

// MISC
export const sendInquiry = async (data: any): Promise<IAPIFrontResponse> => {
  return await fetchApi('POST', '/site/operators/send-inquiry', data)
}

// USER
export const getFavouriteToursIds = async (): Promise<IAPIFrontResponse> => {
  return await fetchApi('GET', '/site/user/favourite-tours')
}
export const toggleFavouriteTour = async (data: any): Promise<IAPIFrontResponse> => {
  return await fetchApi('POST', '/site/user/favourite-tours', data)
}

export const getMe = async (): Promise<IAPIFrontResponse> => {
  return await fetchApi('GET', '/site/user/me')
}

export const updateMe = async (data:any): Promise<IAPIFrontResponse> => {
  return await fetchApi('POST', '/site/user/me', data)
}

export const deactivateMe = async (): Promise<IAPIFrontResponse> => {
  return await fetchApi('POST', '/site/user/deactivate')
}

export const deleteMe = async (): Promise<IAPIFrontResponse> => {
  return await fetchApi('POST', '/site/user/delete')
}


// AUTH
export const login = async (data: ILoginData): Promise<IAPIFrontResponse> => {
  return await fetchApi('POST', '/auth/login', data)
}
export const register = async (data: ILoginData): Promise<IAPIFrontResponse> => {
  return await fetchApi('POST', '/auth/register', data)
}
export const forgotPassword = async (data: any): Promise<IAPIFrontResponse> => {
  return await fetchApi('POST', '/auth/forgot-password', data)
}
export const resetPassword = async (data: any): Promise<IAPIFrontResponse> => {
  return await fetchApi('POST', '/auth/reset-password', data)
}



export const refreshAuth = async (): Promise<IAPIFrontResponse> => {
  return await fetchApi('GET', '/auth/refresh')
}
export const googleAuth = async (data: any): Promise<IAPIFrontResponse> => {
  return await fetchApi('POST', '/auth/google', data)
}


// OPERATOR
export const getOperatorDetails = async(id?: string, slug?: string, operatorExists: boolean = false): Promise<IAPIFrontResponse> => {
  const query = operatorExists ? '?operatorExists=true' : ''
  
  let res
  if(id !== undefined) {
    res = await fetchApi('GET', `/site/operators/${id}${query}`)
    if(res.status_code !== 400) return res
    if(slug === undefined) return res
  }

  res = await fetchApi('GET', `/site/operators/slug__${slug}${query}`)
  return res
}

export const getOperator = async(): Promise<IAPIFrontResponse> => {
  return await fetchApi('GET', '/operator')
}

export const createOperator = async(data: IUpsertOperatorData): Promise<IAPIFrontResponse> => {
  return await fetchApi('POST', '/operator', data)
}

export const updateOperator = async(data: IUpsertOperatorData): Promise<IAPIFrontResponse> => {
  return await fetchApi('PUT', '/operator', data)
}

// OPERATOR BOATS
export const getOperatorBoats = async(): Promise<IAPIFrontResponse> => {
  return await fetchApi('GET', '/operator/boats')
}

export const getOperatorBoat = async(id: number): Promise<IAPIFrontResponse> => {
  return await fetchApi('GET', `/operator/boats/${id}`)
}

export const createOperatorBoat = async(data: IUpsertBoatData): Promise<IAPIFrontResponse> => {
  return await fetchApi('POST', '/operator/boats', data)
}

export const updateOperatorBoat = async(data: IUpsertBoatData): Promise<IAPIFrontResponse> => {
  return await fetchApi('PUT', `/operator/boats/${data.id}`, data)
}

export const deleteOperatorBoat = async(id: number): Promise<IAPIFrontResponse> => {
  return await fetchApi('DELETE', `/operator/boats/${id}`)
}

// OPERATOR TOURS
export const getOperatorToursAvailability = async(tourIds: number[], after: number, before: number): Promise<IAPIFrontResponse> => {
  const queryParams = {
    tourIds: tourIds.join(','),
    after: after,
    before: before
  }
  return await fetchApi('GET', '/operator/tours/availability', null, null, queryParams)
}

export const upsertOperatorOffsiteBooking = async(container: ITourBookingContainer, booking: ITourBookingOffsiteData): Promise<IAPIFrontResponse> => {
  return await fetchApi('POST', '/operator/bookings/offsite-booking/upsert', {
    container,
    booking,
  })
}

export const deleteOperatorOffsiteBooking = async(container: ITourBookingContainer, booking: ITourBookingOffsiteData): Promise<IAPIFrontResponse> => {
  return await fetchApi('POST', '/operator/bookings/offsite-booking/delete', {
    container,
    booking,
  })
}

export const upsertOperatorBookingContainers = async(containers: ITourBookingContainer[]): Promise<IAPIFrontResponse> => {
  return await fetchApi('POST', '/operator/bookings/booking-containers/upsert', {
    containers
  })
}

export const getBookingContainer = async(id: number): Promise<IAPIFrontResponse> => {
  return await fetchApi('GET', `/operator/bookings/booking-container/${id}`)
}

export const cancelBookingContainer = async(id: number, data: any): Promise<IAPIFrontResponse> => {
  return await fetchApi('POST', `/operator/bookings/booking-container/${id}/cancel`, data)
}

export const requestRefundBooking = async(id: number): Promise<IAPIFrontResponse> => {
  return await fetchApi('POST', `/operator/bookings/booking/${id}/request-refund`)
}

export const requestRebookBooking = async(id: number, time: any): Promise<IAPIFrontResponse> => {
  return await fetchApi('POST', `/operator/bookings/booking/${id}/rebook`, time)
}

export const payBooking = async(id: number, paymentMethodId: string): Promise<IAPIFrontResponse> => {
  return await fetchApi('POST', `/operator/bookings/booking/${id}/pay`, {paymentMethodId})
}

export const getUnconfirmedBookings = async(): Promise<IAPIFrontResponse> => {
  return await fetchApi('GET', `/operator/bookings/unconfirmed`)
}

export const resolveUnconfirmedBookings = async(data: any): Promise<IAPIFrontResponse> => {
  return await fetchApi('POST', `/operator/bookings/unconfirmed`, data)
}


export const getOperatorTours = async(): Promise<IAPIFrontResponse> => {
  return await fetchApi('GET', '/operator/tours')
}

export const getOperatorTour = async(id: number): Promise<IAPIFrontResponse> => {
  return await fetchApi('GET', `/operator/tours/${id}`)
}

export const getOperatorTourFormOptions = async(id: number): Promise<IAPIFrontResponse> => {
  return await fetchApi('GET', `/operator/tours/${id}/form-options`)
}

export const createOperatorTour = async(data: IUpsertTourData): Promise<IAPIFrontResponse> => {
  return await fetchApi('POST', '/operator/tours', data)
}

export const updateOperatorTour = async(data: IUpsertTourData): Promise<IAPIFrontResponse> => {
  const res = await fetchApi('PUT', `/operator/tours/${data.id}`, data)
  // console.log('res', res)
  return res
}

export const deleteOperatorTour = async(id: number): Promise<IAPIFrontResponse> => {
  return await fetchApi('DELETE', `/operator/tours/${id}`)
}

// OPERATOR FILES
export const getOperatorFiles = async(): Promise<IAPIFrontResponse> => {
  return await fetchApi('GET', '/operator/files')
}

export const uploadOperatorFiles = async(data: any): Promise<IAPIFrontResponse> => {
  return await fetchApi('POST', `/operator/files`, null, data)
}

export const deleteOperatorFiles = async(data: any): Promise<IAPIFrontResponse> => {
  return await fetchApi('DELETE', `/operator/files?ids=${data.join(',')}`)
}

export const updateOperatorFileDescription = async(data: any): Promise<IAPIFrontResponse> => {
  return await fetchApi('POST', `/operator/files/description`, data)
}

// SLUG
export const checkSlugUniqueness = async(model: string, modelId: number, slug: string): Promise<IAPIFrontResponse> => {
  return await fetchApi('POST', '/operator/slug-unique', {
    model,
    modelId,
    slug
  })
}