import { triggerWindowScroll } from '../utils/responsive'
import React, { useState, useRef, useContext} from 'react'
import Modal from 'react-bootstrap/Modal'
export const ConfirmationModalContext = React.createContext({})
export const useConfirmationModalContext = () => useContext(ConfirmationModalContext) as {showConfirmation: (mesage:string) => Promise<boolean>}

export const ConfirmationModalContextProvider = (props: any) => {
  const [showConfirmationModal, setShowConfirmationModal] = useState(false)
  const [message, setMessage] = useState('')
  const resolver = useRef<(value: unknown) => void>()

  const handleShow = (m: string) => {
    setMessage(m)
    setShowConfirmationModal(true)

    return new Promise(function (resolve) {
      resolver.current = resolve
    })
  }

  const handleOk = () => {
    resolver.current && resolver.current(true)
    setShowConfirmationModal(false)
  }

  const handleCancel = () => {
    resolver.current && resolver.current(false)
    setShowConfirmationModal(false)
  }

  return (
    <ConfirmationModalContext.Provider value={{showConfirmation: handleShow}}>
      {props.children}

      <Modal show={showConfirmationModal} onHide={() => { setShowConfirmationModal(false); triggerWindowScroll()}} centered dialogClassName="modal-xs modal-dialog--are-you-sure">
        <Modal.Body>
          <p>{message}</p>
        </Modal.Body>

        <Modal.Footer>
          <button className="btn btn-default" onClick={handleCancel}  aria-label="Cancel">Cancel</button>
          <button className="btn btn-primary" onClick={handleOk} aria-label="Ok">OK</button>
        </Modal.Footer>
      </Modal>

    </ConfirmationModalContext.Provider>
  )
}