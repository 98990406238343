exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-operator-analytics-tsx": () => import("./../../../src/pages/operator/analytics.tsx" /* webpackChunkName: "component---src-pages-operator-analytics-tsx" */),
  "component---src-pages-operator-boats-[id]-tsx": () => import("./../../../src/pages/operator/boats/[id].tsx" /* webpackChunkName: "component---src-pages-operator-boats-[id]-tsx" */),
  "component---src-pages-operator-boats-index-tsx": () => import("./../../../src/pages/operator/boats/index.tsx" /* webpackChunkName: "component---src-pages-operator-boats-index-tsx" */),
  "component---src-pages-operator-cancel-tour-[id]-tsx": () => import("./../../../src/pages/operator/cancel-tour/[id].tsx" /* webpackChunkName: "component---src-pages-operator-cancel-tour-[id]-tsx" */),
  "component---src-pages-operator-config-index-tsx": () => import("./../../../src/pages/operator/config/index.tsx" /* webpackChunkName: "component---src-pages-operator-config-index-tsx" */),
  "component---src-pages-operator-gallery-tsx": () => import("./../../../src/pages/operator/gallery.tsx" /* webpackChunkName: "component---src-pages-operator-gallery-tsx" */),
  "component---src-pages-operator-index-tsx": () => import("./../../../src/pages/operator/index.tsx" /* webpackChunkName: "component---src-pages-operator-index-tsx" */),
  "component---src-pages-operator-slug-tour-slug-tsx": () => import("./../../../src/pages/[operatorSlug]/[tourSlug].tsx" /* webpackChunkName: "component---src-pages-operator-slug-tour-slug-tsx" */),
  "component---src-pages-operator-slug-tsx": () => import("./../../../src/pages/[operatorSlug].tsx" /* webpackChunkName: "component---src-pages-operator-slug-tsx" */),
  "component---src-pages-operator-tours-[id]-tsx": () => import("./../../../src/pages/operator/tours/[id].tsx" /* webpackChunkName: "component---src-pages-operator-tours-[id]-tsx" */),
  "component---src-pages-operator-tours-index-tsx": () => import("./../../../src/pages/operator/tours/index.tsx" /* webpackChunkName: "component---src-pages-operator-tours-index-tsx" */),
  "component---src-pages-site-account-index-tsx": () => import("./../../../src/pages/site/account/index.tsx" /* webpackChunkName: "component---src-pages-site-account-index-tsx" */),
  "component---src-pages-site-account-reset-password-tsx": () => import("./../../../src/pages/site/account/reset-password.tsx" /* webpackChunkName: "component---src-pages-site-account-reset-password-tsx" */),
  "component---src-pages-site-become-an-operator-index-tsx": () => import("./../../../src/pages/site/become-an-operator/index.tsx" /* webpackChunkName: "component---src-pages-site-become-an-operator-index-tsx" */),
  "component---src-pages-site-bookings-[id]-tsx": () => import("./../../../src/pages/site/bookings/[id].tsx" /* webpackChunkName: "component---src-pages-site-bookings-[id]-tsx" */),
  "component---src-pages-site-bookings-comment-tsx": () => import("./../../../src/pages/site/bookings/comment.tsx" /* webpackChunkName: "component---src-pages-site-bookings-comment-tsx" */),
  "component---src-pages-site-bookings-fail-tsx": () => import("./../../../src/pages/site/bookings/fail.tsx" /* webpackChunkName: "component---src-pages-site-bookings-fail-tsx" */),
  "component---src-pages-site-bookings-index-tsx": () => import("./../../../src/pages/site/bookings/index.tsx" /* webpackChunkName: "component---src-pages-site-bookings-index-tsx" */),
  "component---src-pages-site-bookings-payment-intent-check-tsx": () => import("./../../../src/pages/site/bookings/payment-intent-check.tsx" /* webpackChunkName: "component---src-pages-site-bookings-payment-intent-check-tsx" */),
  "component---src-pages-site-legal-notice-index-tsx": () => import("./../../../src/pages/site/legal/notice/index.tsx" /* webpackChunkName: "component---src-pages-site-legal-notice-index-tsx" */),
  "component---src-pages-site-legal-operator-tac-index-tsx": () => import("./../../../src/pages/site/legal/operator-tac/index.tsx" /* webpackChunkName: "component---src-pages-site-legal-operator-tac-index-tsx" */),
  "component---src-pages-site-legal-platform-rules-index-tsx": () => import("./../../../src/pages/site/legal/platform-rules/index.tsx" /* webpackChunkName: "component---src-pages-site-legal-platform-rules-index-tsx" */),
  "component---src-pages-site-legal-privacy-policy-index-tsx": () => import("./../../../src/pages/site/legal/privacy-policy/index.tsx" /* webpackChunkName: "component---src-pages-site-legal-privacy-policy-index-tsx" */),
  "component---src-pages-site-legal-security-index-tsx": () => import("./../../../src/pages/site/legal/security/index.tsx" /* webpackChunkName: "component---src-pages-site-legal-security-index-tsx" */),
  "component---src-pages-site-legal-tac-index-tsx": () => import("./../../../src/pages/site/legal/tac/index.tsx" /* webpackChunkName: "component---src-pages-site-legal-tac-index-tsx" */),
  "component---src-pages-site-messages-index-tsx": () => import("./../../../src/pages/site/messages/index.tsx" /* webpackChunkName: "component---src-pages-site-messages-index-tsx" */),
  "component---src-pages-site-operators-[id]-tsx": () => import("./../../../src/pages/site/operators/[id].tsx" /* webpackChunkName: "component---src-pages-site-operators-[id]-tsx" */),
  "component---src-pages-site-tours-[id]-tsx": () => import("./../../../src/pages/site/tours/[id].tsx" /* webpackChunkName: "component---src-pages-site-tours-[id]-tsx" */),
  "component---src-pages-site-tours-index-tsx": () => import("./../../../src/pages/site/tours/index.tsx" /* webpackChunkName: "component---src-pages-site-tours-index-tsx" */),
  "component---src-pages-site-wishlist-index-tsx": () => import("./../../../src/pages/site/wishlist/index.tsx" /* webpackChunkName: "component---src-pages-site-wishlist-index-tsx" */),
  "component---src-templates-index-tsx": () => import("./../../../src/templates/index.tsx" /* webpackChunkName: "component---src-templates-index-tsx" */)
}

