import * as React from "react"
import {getDateFromUnix, getTimeFromUnix, getSingleTimeFromUnix} from '../shared/utils/time'


export const getDateNiceFormatting = (dateStart: number, dateEnd: number, twoLine:boolean=false, showSingleDate: boolean = true) => {
    const s = getDateFromUnix(dateStart)
    const e = getDateFromUnix(dateEnd)
    if (s !== e) {
      return <><b className="twoline">{s}</b>({getSingleTimeFromUnix(dateStart)}){(twoLine ? <br /> : ' - ')}<b className="twoline">{e}</b>({getSingleTimeFromUnix(dateEnd)}) </>
    }

    return <>{showSingleDate && s} <b> {getTimeFromUnix(dateStart, dateEnd)}</b></>
  }