import React from 'react'
import './loaders.scss'

export function LoadingOverlay(props: any): JSX.Element {
  return props.loading && <div className="loading-overlay"><div className="lds-dual-ring"></div> {props.children}</div> || ''
}

export function LoadingCover(props: any): JSX.Element {
  return props.loading && <div className="loading-cover"><div className="lds-dual-ring"></div>{props.children}</div> || ''
}

export function LoadingDiv(props: any): JSX.Element {
  return props.loading && <div className="loading-div"><div className="lds-dual-ring"></div>{props.children}</div> || ''
}

export function JqueryLoadingDiv(props: any): JSX.Element {
  return <div className="loading-div"><div className="lds-dual-ring"></div>{props.children}</div> || ''
}