import { isBrowser } from "./browser"
import * as api from './api'

export const favourites_ls_key = "tour_boats_favourites"

export const getToken = (): number[] => {
  const token = isBrowser() && window.localStorage.getItem(favourites_ls_key)
  return isBrowser() && token && token !== 'undefined' ? JSON.parse(token) : []
}

export const setToken = (data: number[]) => {
  isBrowser() && window.localStorage.setItem(favourites_ls_key, JSON.stringify(data))
}

export const refreshFavourites = async () => {
  const res = await api.getFavouriteToursIds()
  const d = res.data
  setToken(d)
}

export const getFavourites = () => {
  return getToken()
}

export const toggleFavourite = async (tourId: number) => {
  const res = await api.toggleFavouriteTour({tourId})
  const connected = res.data.connected

  let token = getToken()

  if(connected === false) {
    const idx = token.findIndex((n: number) => n === tourId)
    token.splice(idx, 1)
  }

  if(connected === true) {
    token.push(tourId)
  }

  setToken(token)
}
