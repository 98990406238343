import React, { useState, useContext } from 'react'
import Modal from 'react-bootstrap/Modal'
import { Formik, Form, Field } from 'formik'
import { resolveFormSubmit } from "../utils/form"
import Wysiwyg from '../components/form/wysiwyg/Wysiwyg'
import IAPIFrontResponse from "../types/IAPIFrontResponse"
import { LoadingCover } from '../components/loaders'
import { triggerWindowScroll } from '../utils/responsive'
import * as api from '../services/api'
import { FormResponseMsgSuccess, FormResponseMsgError } from '../components/form/formResponse/FormResponse'
export const CustomRequestModalContext = React.createContext({})
export const useCustomRequestModalContext = () => useContext(CustomRequestModalContext) as {showModal: (data: CustomRequestData) => Promise<void>}

export type CustomRequestData = {
  operatorId: number|null
  tourId: number|null
  message: string
}


export const CustomRequestModalContextProvider = (props: any) => {
  const [showModal, setShowModal] = useState(false)
  const [successMsg, setSuccessMsg] = useState<string | null>(null)
  const [errorMsg, setErrorMsg] = useState<string | null>(null)

  const [data, setData] = useState<CustomRequestData>({
    operatorId: null,
    tourId: null,
    message: "",
  })

  const handleShow = (d: CustomRequestData) => {
    setData(d)
    setShowModal(true)
  }

  const sendMessage = async (data: any) => {
    setSuccessMsg(null)
    setErrorMsg(null)
    const res = await api.sendInquiry(data)
    return res
  }

  const success = async (data: any, resp: IAPIFrontResponse) => {
    setSuccessMsg("Your message was taken. We will respond shortly")
    setTimeout(() => setShowModal(false), 5000)
  }

  const error = async (data: any, resp: IAPIFrontResponse) => {
    resp.message && setErrorMsg(resp.message)
  }


  return (
    <CustomRequestModalContext.Provider value={{ showModal: handleShow }}>
      {props.children}

      <Modal show={showModal} onHide={() => { setShowModal(false); triggerWindowScroll()}} centered dialogClassName="modal-xl modal-dialog modal-dialog--custom-request">
        <Modal.Body>
          <i className="modal-body__close" onClick={() => setShowModal(false)}>x</i>
          <h1 className='section-title'>Send an enquiry</h1>

          <FormResponseMsgSuccess message={successMsg} />
          <FormResponseMsgError message={errorMsg} />

          <Formik
            initialValues={data}
            validate={values => {
              return []
            }}
            onSubmit={async (values, { setSubmitting }) => {
                await resolveFormSubmit(values, {
                  onSubmit: sendMessage,
                  onSuccess: success,
                  onError: error,
                })
                setSubmitting(false)
            }}
          >
            {({ isSubmitting, isValid }) => (
                <Form className="form">
                  <div className="form__row">
                    {/* <label>Description<ErrorMessage className="form__row__error" name="message" component="i" /></label> */}
                    <Field
                      className="form__wysiwyg"
                      name="message"
                      component={Wysiwyg}
                      placeholder="Write your enquiry"
                      isMulti={true}
                    />
                  </div>

                  <button className="button" type="submit" aria-label="Send Enquiry">Send Enquiry</button>
                  <LoadingCover loading={isSubmitting} />
                </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </CustomRequestModalContext.Provider>
  )
}
