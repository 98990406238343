import React, { useMemo } from "react"
// https://www.npmjs.com/package/react-responsive-picture
// import { FullsizePicture} from 'react-responsive-picture'
import { useResponsiveData } from "../../utils/responsive"
import _ from 'lodash'
import './image-card.scss'

// TODO: make this a carousel that can have videos
export default function ImageCard({images, windowWidthPercent=30, fullWidthBreakpointPx=500, lazyLoad=false}: any) {
    
    const responsiveData = useResponsiveData()

    if(responsiveData.isMobile) windowWidthPercent=100

    let pictures = useMemo(() => {
        return images.map((img: any) => {
            const srcSet = _.cloneDeep(img.srcSet)
            const originalIndex = srcSet.findIndex((v: any) => v.width === 0)
            const original = srcSet.splice(originalIndex, 1)

            const biggestWidth = srcSet.reduce((acc: number, v: any) => v.width > acc ? v.width : acc, 0)
            const smallestWidth = srcSet.reduce((acc: number, v: any) => v.width < acc ? v.width : acc, 10000)

            let smallest = original

            const sources = srcSet.map((v: any) => {
                let minWidth = Math.round((100/windowWidthPercent)*(v.width - 400))
                if(v.width === biggestWidth) {
                    minWidth = 10000
                }
                
                // if(v.width < fullWidthBreakpointPx) {
                //     maxWidth
                // }

                if(v.width === smallestWidth) {
                    smallest = v
                }

                return {
                    srcSet: `${v.uri} 1x, ${v.uri2x} 2x`,
                    media: `(min-width: ${minWidth}px)`
                }
            })

            return {
                sources,
                smallestSrcSet: `${smallest.uri} 1x, ${smallest.uri2x} 2x`,
                smallest: `${smallest.uri}`,
                original: `${original.uri} 1x, ${original.uri2x} 2x`,
            }
        })
    } , [windowWidthPercent, images])
    

    // if(pictures.length === 0) {
    //     pictures = [
    //         {
    //             sources: [{
    //                 srcSet: `/images/tour_boats_logo.png 1x, /images/tour_boats_logo.png 2x`,
    //             }],
    //             smallestSrcSet: `/images/tour_boats_logo.png 1x, /images/tour_boats_logo.png 2x`,
    //             smallest: `/images/tour_boats_logo.png`,
    //             original: `/images/tour_boats_logo.png 1x, /images/tour_boats_logo.png 2x`,
    //         }
    //     ]
    // }


    const pic = pictures[0]

    return <div className="image-card" data-x={windowWidthPercent}>
        {pic?.sources && <picture>
            {pic?.sources.map((s:any, i: number) => {
                return <source key={i} srcSet={s.srcSet} media={s.media} />
            })}

            <img src={pic.smallest} srcSet={pic.smallestSrcSet} loading={lazyLoad? "lazy": "eager"}/>
        </picture>}

        {/* <FullsizePicture
            sources={pic.sources}
            // src={pic.original}
        /> */}
    </div>
}