import * as React from "react"
import { useCallback, useEffect, useMemo, useState } from "react"
import ICustomCountConfig from '../../../shared/types/ICustomCountConfig'
import { getCustomCountConfigErrors } from '../../../shared/utils/customCountConfig'
import './custom-count-config-widget.scss'


const CustomCountConfigWidget = ({config, onConfigChanged, minItems, maxItems, showImages=false, showIcons=false}: {config: ICustomCountConfig[], onConfigChanged: (config: ICustomCountConfig[]) => void, maxItems: number, minItems: number, showImages?: boolean, showIcons?: boolean}) => {
    const [localConfig, setLocalConfig] = useState<ICustomCountConfig[]>(config || [])

    const modifySelected = useCallback((id: string, n: number) => {
        const item = localConfig.find((c: ICustomCountConfig) => c.id === id)
        if(!item) return

        item.count += n
        if(item.count < 0) item.count = 0

        const newConfig = [...localConfig]
        setLocalConfig(newConfig)
        onConfigChanged(newConfig)
    }, [config])

    const mod = (title: string, n: number) => {
        modifySelected(title, n)
    }

    const countLimitError = useMemo(() => {
        return getCustomCountConfigErrors(config, minItems, maxItems)
    }, [config, minItems, maxItems])

    // todo: Add per image check too so the height is not weird
    return <div className={"custom-count-config-widget" + (showImages ? " custom-count-config-widget--with-images" : "")  + (showIcons ? " custom-count-config-widget--with-icons" : "")}>
        {localConfig.map((c:ICustomCountConfig, i:number) => {
            return <div key={i} className="custom-count-config-widget__item">
                {c.image && <div style={{backgroundImage: `url(${c.image})`}} className="custom-count-config-widget__item__image" ></div>}
                {c.icon && <div className="custom-count-config-widget__item__icon" dangerouslySetInnerHTML={{__html: c.icon.svg?.replace('\\"', '"')}}></div>}

                <div className={"custom-count-config-widget__item__details"}>
                    <span>{c.name}</span>
                    {c.description && <p>{c.description}</p>}
                </div>
                <div className={"custom-count-config-widget__item__buttons" + (c.singular_item ? ' custom-count-config-widget__item__buttons--checkbox-wrapper' : '')}>
                    {!c.singular_item && <>
                        <div className="button button--invert" onClick={() => mod(c.id, -1)}>-</div>
                        <span className="custom-count-config-widget__item__buttons__count">{c.count}</span>
                        <div className="button button--invert" onClick={() => mod(c.id, 1)}>+</div>
                    </>}

                    {c.singular_item && <>
                        <input type="checkbox" checked={c.count === 1} onChange={() => mod(c.id, c.count === 1 ? -1 : 1)} />
                    </>}
                </div>
            </div>
        })}

        {countLimitError && <div className="custom-count-config-widget__error">{ countLimitError }</div>}
    </div>
}


export default CustomCountConfigWidget

