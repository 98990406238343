import IFormParams from "../types/IFormParams"


export const resolveFormSubmit = async (values: any, args: IFormParams) => {
  console.log('start')
  const response:any = await args.onSubmit(values)
  console.log('end', response)
  if (response.success) {
    await args.onSuccess(values, response)
  } else {
    await args.onError(values, response)
  }
}