import * as React from "react"
import { useCallback, useEffect, useMemo, useState } from "react"
import { loadStripe, StripeElementsOptions } from "@stripe/stripe-js"
import _ from "lodash"
import moment from "moment"
import { Elements} from "@stripe/react-stripe-js"
import config from '../../../services/config'
import { isLoggedIn } from "../../../services/auth"
import { getTourBookingConfirm, getTourBookingCheckoutData } from "../../../services/api"
import ITourConfirmData from '../../../shared/types/ITourConfirmData'
import ITourBookingData from "../../../shared/types/ITourBookingData"
import ICustomCountConfig from "../../../shared/types/ICustomCountConfig"
import { PriceData, calculatePrice } from "../../../shared/utils/price"
import { getDateFromUnix, getTimeFromUnix } from '../../../shared/utils/time'
import { IResponsiveData, useResponsiveData } from "../../../utils/responsive"
import { getCustomCountConfigFromOccupancy, getOccupancyFromCustomCountConfig, getCustomCountConfigFromMenu, getCustomCountConfigFromUpsells} from "../../../shared/utils/customCountConfig"
import TourCard from "../../../components/tourCard/TourCard"
import CustomCountConfigWidget from "../../../components/form/customCountConfigWidget/CustomCountConfigWidget"
import { LoginWidget } from "../../../components/auth/loginWidget/loginWidget"
import { LoadingCover, LoadingDiv } from '../../loaders'
import CheckoutForm from "./CheckoutForm"


import "./book-confirm.scss"


const stripePromise = loadStripe(config.stripePk)

const BookCreditCardConfig = ({tourConfirmData, bookingData, setConfirmView}: {tourConfirmData: ITourConfirmData, bookingData: ITourBookingData, setConfirmView: (v:string) => void}) => {
  const [booking, setBooking] = useState<null|ITourBookingData>(null)
  const [user, setUser] = useState<null|any>(null)
  const [checkoutFormIsLoading, setCheckoutFormIsLoading] = useState(false)

  const options = {
    mode: 'payment',
    amount: booking?.price && Math.round(booking.price * 100) || 0,
    currency: 'eur',
    paymentMethodCreation: 'manual',
    paymentMethodTypes: ['card'],
    appearance: {
      theme: 'stripe',
    },
  } as StripeElementsOptions

  useEffect(()=> {
    const g = async () => {
      const res = await getTourBookingCheckoutData({tourConfirmData, bookingData})
      setBooking(res.data.booking)
      setUser(res.data.user)
    }
    g()
  }, [])

  return (
    <div className="tour-book-confirm__controls__payment">
      <button className="button button--minimalistic tour-book-confirm__back-button" onClick={() => setConfirmView('config')}>
        <h2>{!checkoutFormIsLoading && "< Back to config"}</h2> 
      </button>
      <LoadingDiv loading={true} />
      {tourConfirmData.published && booking && user && <>
        <Elements options={options} stripe={stripePromise}>
          <CheckoutForm booking={booking} user={user} isLoading={checkoutFormIsLoading} setIsLoading={setCheckoutFormIsLoading} />
          <p className="tour-book-confirm__controls__payment__powered-by">powered by <a href="https://stripe.com" target="_blank">Stripe</a></p>
          <p className="tour-book-confirm__controls__payment__privacy-text">Your personal data will be used to process your order, support your experience throughout this website, and for other purposes described in our <a href="https://tour.boats/site/legal/privacy-policy/" target="_blank">privacy policy.</a></p>
        </Elements>
      </>}
      {!tourConfirmData.published && <p>Booking a tour that is not published is not possible</p>}
    </div>
  )
}

const BookConfirmConfig = ({tourConfirmData, bookingData, menuData, setMenuData, upsellsData, setUpsellsData, occupancyData, setOccupancyData}: {tourConfirmData: ITourConfirmData, bookingData: ITourBookingData, menuData: any, setMenuData: any, upsellsData: any, setUpsellsData: any, occupancyData: any, setOccupancyData:any}) => {
    const setOccupancy =  (data: ICustomCountConfig[]) => {
      setOccupancyData(getOccupancyFromCustomCountConfig(data))
    }
    
    const occupancy = useMemo(() => {
      const o = _.cloneDeep(occupancyData)
      if(!tourConfirmData.childrenSuitable) delete o.children
      if(!tourConfirmData.infantsSuitable) delete o.infants
      return getCustomCountConfigFromOccupancy(o, tourConfirmData.childrenMaxAge, tourConfirmData.infantsMaxAge)
    }, [occupancyData, tourConfirmData])

    const occupancyTotal: number = useMemo(() => {
      return Object.values(occupancyData).reduce((acc:any, v:any) => acc + v, 0) as number
    }, [occupancyData])

    const menuTotal: number = useMemo(() => {
      return Object.values(menuData).reduce((acc:any, item:any) => acc + item.count, 0) as number
    }, [menuData])

    const errors: string[] = useMemo(() => {
      return []
      // let res = []
      // if (menuData.length && occupancyTotal !== menuTotal) {
      //   res.push(`Number of menu items differs from number of guests, please double check before proceeding`)
      // }

      // return res
    }, [menuData, occupancyTotal, menuTotal])

    const warnings: string[] = useMemo(() => {
      let res = []
      if (menuData.length && occupancyTotal !== menuTotal) {
        res.push(`Number of menu items differs from number of guests, please double check before proceeding`)
      }

      return res
    }, [menuData, occupancyTotal, menuTotal])

    return <div className="tour-book-confirm__controls__config">
      <label>Your experience</label>
      <span>{tourConfirmData.name}</span>

      <label>Date</label>
      <span>{getDateFromUnix(bookingData.startTime)} {getTimeFromUnix(bookingData.startTime, bookingData.endTime)}</span>
      

      <label>Guests (up to {tourConfirmData.seatsAvailable})</label>
      <CustomCountConfigWidget 
        config={occupancy} 
        onConfigChanged={setOccupancy} 
        maxItems={tourConfirmData.seatsAvailable} 
        minItems={1} 
        showImages={false} />

      {menuData.length > 0 && <>
        <label>Menu</label>
        <CustomCountConfigWidget
          config={menuData} 
          onConfigChanged={setMenuData} 
          maxItems={1000} 
          minItems={0} 
          showImages={true}/>
      </>} 

      {upsellsData.length > 0 && <>
        <label>Aditional items</label>
        <CustomCountConfigWidget
          config={upsellsData} 
          onConfigChanged={setUpsellsData} 
          maxItems={1000} 
          minItems={0} 
          showIcons={true}/>
      </>} 
     
      {warnings.length > 0 && <div className="tour-book-confirm__warnings">
        {warnings.map((w: string, i: number) => <span key={i}>{w}</span>)}
      </div>}

      {errors.length > 0 && <div className="tour-book-confirm__errors">
        {errors.map((e: string, i: number) => <span key={i}>{e}</span>)}
      </div>}
    </div>
}


const PriceBreakdown = ({tourConfirmData, priceData, bookingData}: {tourConfirmData: ITourConfirmData, priceData: PriceData,  bookingData: ITourBookingData }) => {
  // console.log('priceData', priceData)
  // console.log('tourConfirmData', tourConfirmData)
  // console.log('bookingData', bookingData)

  return <div className="tour-book-confirm__details__price">
    <label>Price breakdown:</label>
    <hr />
    <span>{tourConfirmData.private ? "Tour price" : "Ticket price"}: € {priceData.basePrice} {bookingData.bookedPrivately ? `x ${tourConfirmData.boatCapacity}` : ''}</span>
    
    {/* {priceData.occupancy > 0 && <span>Tickets price: € {priceData.occupancy} </span>}
    {priceData.menu > 0 && <span>Menu price: € {priceData.menu}</span>} */}

    {/* {JSON.stringify(priceData, null, 2)} */}

    {bookingData.bookedPrivately && <>
      <hr />
      {(priceData.baseDiscountPercent || 0) > 0 && <span>Discount: <br />{priceData.baseDiscountReason.join(",")}</span>}
    </>}

    {priceData.occupancy.total > 0 && <>
      <hr />
      {priceData.occupancy.adultsPrice > 0 && <span>Adults: {priceData.occupancy.adultsNumber} x {priceData.occupancy.adultsPrice} = {priceData.occupancy.adultsTotal} €</span>}
      {priceData.occupancy.childrenPrice > 0 && <span>Children {priceData.occupancy.childrenDiscountPercent && priceData.occupancy.childrenDiscountPercent > 0 ? `(-${priceData.occupancy.childrenDiscountPercent}%)` : ""}: {priceData.occupancy.childrenNumber} x {priceData.occupancy.childrenPrice} = {priceData.occupancy.childrenTotal} €</span>}
      {priceData.occupancy.infantsPrice > 0 && <span>Infants {priceData.occupancy.infantsDiscountPercent && priceData.occupancy.infantsDiscountPercent > 0 ? `(-${priceData.occupancy.infantsDiscountPercent}%)` : ""}: {priceData.occupancy.infantsNumber} x {priceData.occupancy.infantsPrice} = {priceData.occupancy.infantsTotal} €</span>}
      <span>Total: {priceData.occupancy.total} €</span>

      {(priceData.baseDiscountPercent || 0) > 0 && <span><br />Discount: <br />{priceData.baseDiscountReason.join(",")} = {priceData.baseDiscountPercent}% </span>}
      {(priceData.baseDiscountPercent || 0) > 0 && (priceData.menu.total !== 0 || priceData.upsells.total !== 0) && <span>New price: {priceData.baseDiscountPrice} €</span>}
    </>}

  
    {priceData.menu.total > 0 && <>
      <hr />
      {priceData.menu.items.filter((m) => m.count > 0).map((m, i:number) => <span key={i}>{m.name}: {m.count} x {m.price} = {m.total} €</span>)}
      <span>Total: {priceData.menu.total} €</span>
    </>}

    {priceData.upsells.total > 0 && <>
      <hr />
      {priceData.upsells.items.filter((m) => m.count > 0).map((m, i:number) => <span key={i}>{m.name}: {m.count} x {m.price} = {m.total} €</span>)}
      <span>Total: {priceData.upsells.total} €</span>
    </>}

    <hr />
    <span><b>Total price: {priceData.total} €</b></span>
  </div>
}

const ConfirmViewConfig = ({
  tourConfirmData, 
  priceData, 
  responsiveData,
  isPrivate,
  bookingData,
  menuData,
  setMenuData,
  upsellsData,
  setUpsellsData,
  occupancyData,
  setOccupancyData,
  callNext
} : {
    tourConfirmData: ITourConfirmData, 
    priceData: PriceData, 
    responsiveData: IResponsiveData
    isPrivate: boolean,
    bookingData: ITourBookingData,
    menuData: any,
    setMenuData: any,
    upsellsData: any,
    setUpsellsData: any,
    occupancyData: any,
    setOccupancyData: any,
    callNext: any

  }) => {

  return <>
    <div className="tour-book-confirm__content">
      <div className="tour-book-confirm__controls">
        {responsiveData.isMobile && <TourCard
          id={tourConfirmData.id} 
          name={tourConfirmData.name} 
          slug={tourConfirmData.slug} 
          private={isPrivate} 
          published={tourConfirmData.published} 
          description={tourConfirmData.description} 
          tourType={tourConfirmData.tourType} 
          coverImages={tourConfirmData.coverImages} 
          votes={tourConfirmData.votes} 
          stars={0} 
          baseDuration={0} 
          basePrice={0} 
          menu={null} 
          boats={null} 
          upsells={undefined} 
          hideFavouriteIcon={true} 
        />}

        <BookConfirmConfig 
          tourConfirmData={tourConfirmData} 
          bookingData={bookingData}
          menuData={menuData} 
          setMenuData={setMenuData}
          upsellsData={upsellsData}
          setUpsellsData={setUpsellsData}
          occupancyData={occupancyData}
          setOccupancyData={setOccupancyData}
        />
      </div>

      <div className="tour-book-confirm__details">
        {!responsiveData.isMobile && <TourCard 
          id={tourConfirmData.id} 
          name={tourConfirmData.name} 
          slug={tourConfirmData.slug} 
          private={isPrivate} 
          published={tourConfirmData.published} 
          description={tourConfirmData.description} 
          tourType={tourConfirmData.tourType} 
          coverImages={tourConfirmData.coverImages} 
          votes={tourConfirmData.votes} 
          stars={tourConfirmData.stars} 
          baseDuration={0} 
          basePrice={0} 
          menu={null} 
          boats={null} 
          upsells={undefined} 
          hideFavouriteIcon={true} 
        />}

        {/* {!(responsiveData.isMobile) && priceData && <PriceBreakdown tourConfirmData={tourConfirmData} priceData={priceData} />} */}
        {priceData && <PriceBreakdown tourConfirmData={tourConfirmData} priceData={priceData}  bookingData={bookingData} />}
      </div>
    </div>


    <button className="button button--invert button--big button-confirm-and-pay" onClick={() => callNext()}>
      <h2>Proceed With Booking</h2> 
      <p>Confirm your setup <br /> and proceed to checkout view</p>
    </button>

    <div style={{width: "100%", height: "50px"}}></div>
  </>
}


const ConfirmViewLogin = ({
  tourConfirmData, 
  priceData, 
  responsiveData,
  isPrivate,
  bookingData,
  setConfirmView,
  refreshLoggedIn
} : {
    tourConfirmData: ITourConfirmData, 
    priceData: PriceData, 
    responsiveData: IResponsiveData
    isPrivate: boolean,
    bookingData: ITourBookingData,
    setConfirmView: any
    refreshLoggedIn: any

  }) => {

  return <>
    <div className="tour-book-confirm__content">
      <div className="tour-book-confirm__controls">
        <button className="button button--minimalistic tour-book-confirm__back-button" onClick={() => setConfirmView('config')}>
          <h2>{"<"} Back to config</h2> 
        </button>

        <LoginWidget 
          view="register"
          hideMessage={true}
          backToLoginText="Have an account? Log In" 
          loginBtnTxt="Login and proceed"
          registerBtnTxt="Register and proceed"
          afterLogin={(data: any) => {
            refreshLoggedIn()
            setConfirmView('payment')
          }}
        />
      </div>

      <div className="tour-book-confirm__details">
        {!responsiveData.isMobile && <TourCard 
          id={tourConfirmData.id} 
          name={tourConfirmData.name} 
          slug={tourConfirmData.slug} 
          private={isPrivate} 
          published={tourConfirmData.published} 
          description={tourConfirmData.description} 
          tourType={tourConfirmData.tourType} 
          coverImages={tourConfirmData.coverImages} 
          votes={tourConfirmData.votes} 
          stars={tourConfirmData.stars} 
          baseDuration={0} 
          basePrice={0} 
          menu={null} 
          boats={null} 
          upsells={undefined} 
          hideFavouriteIcon={true} 
        />}
        
        {!(responsiveData.isMobile) && priceData && <PriceBreakdown tourConfirmData={tourConfirmData} priceData={priceData} bookingData={bookingData} />}
      </div>
    </div>
  </>
}

const ConfirmViewPayment = ({
  tourConfirmData, 
  priceData, 
  responsiveData,
  isPrivate,
  bookingData,
  setConfirmView
} : {
    tourConfirmData: ITourConfirmData, 
    priceData: PriceData, 
    responsiveData: IResponsiveData
    isPrivate: boolean,
    bookingData: ITourBookingData,
    setConfirmView: any

  }) => {

  return <>
    <div className="tour-book-confirm__content">
      <div className="tour-book-confirm__controls">
        <BookCreditCardConfig 
          tourConfirmData={tourConfirmData}
          bookingData={bookingData}
          setConfirmView={setConfirmView}
        />
      </div>


      <div className="tour-book-confirm__details">
        {!responsiveData.isMobile && <TourCard 
          id={tourConfirmData.id} 
          name={tourConfirmData.name} 
          slug={tourConfirmData.slug} 
          private={isPrivate} 
          published={tourConfirmData.published} 
          description={tourConfirmData.description} 
          tourType={tourConfirmData.tourType} 
          coverImages={tourConfirmData.coverImages} 
          votes={tourConfirmData.votes} 
          stars={tourConfirmData.stars} 
          baseDuration={0} 
          basePrice={0} 
          menu={null} 
          boats={null} 
          upsells={undefined} 
          hideFavouriteIcon={true} 
        />}
        
        {!(responsiveData.isMobile) && priceData && <PriceBreakdown tourConfirmData={tourConfirmData} priceData={priceData} bookingData={bookingData} />}
      </div>
    </div>
  </>
}


const BookConfirm = ({data}: {data: any}) => {
  const [confirmView, setConfirmView] = useState('config')
  const [isLoading, setIsLoading] = useState(false)
  const [tourConfirmData, setTourConfirmData] = useState<null | ITourConfirmData>(null)
  const [menuData, setMenuData] = useState<ICustomCountConfig[]>([])
  const [upsellsData, setUpsellsData] = useState<ICustomCountConfig[]>([])
  const [occupancyData, setOccupancyData] = useState({
    adults: 1,
    children: 0,
    infants: 0,
  })

  const responsiveData = useResponsiveData()

  const [loggedIn, setLoggedIn] = useState<boolean>(isLoggedIn(null))
  const refreshLoggedIn = () => {
    setLoggedIn(isLoggedIn(null))
  }
  
  const getConfirmData = useCallback(async () => {
    setIsLoading(true)
    const res = await getTourBookingConfirm(data.tourId, data.time.startTime)
    setTourConfirmData(res.data)
    setMenuData(getCustomCountConfigFromMenu(res.data.menu))
    setUpsellsData(getCustomCountConfigFromUpsells(res.data.upsells))
    setIsLoading(false)
  }, [data.tourId, data.time.startTime])

  useEffect(() => {
    getConfirmData()
  }, [data.tourId, data.time.startTime])

  const bookingData = useMemo(() => {
    const upsellsDurationHours = upsellsData.map((upsell) => {
      const d = upsell?.extraData
      if(!upsell.count) return 0
      if(!d.duration) return 0
      return d.duration
    }).reduce((acc: number, h: number) => acc + h, 0)

    return {
      tourId: data.tourId,
      startTime: data.time.startTime,
      endTime: data.time.endTime + upsellsDurationHours*60*60,
      private: data.private,
      bookedPrivately: data.bookPrivately,
      discounts: [],
      upsells: upsellsData,
      menu: menuData,
      occupancy: occupancyData,
      now: moment.now(),
    } as ITourBookingData
  }, [data, menuData, occupancyData, upsellsData])

  const priceData = useMemo(() => {
    return tourConfirmData && calculatePrice(tourConfirmData, bookingData)
  }, [tourConfirmData, bookingData])

  return (
    <div className="tour-book-confirm">
      <div className="tour-book-confirm__header">
        {confirmView === 'config' && <h2>Setup your experience:</h2>}
        {confirmView === 'login' && <h2>Enter your details:</h2>}
        {confirmView === 'payment' && <h2>Recieve your tickets:</h2>}
      </div>


      {tourConfirmData && <>
        {confirmView === 'config' && priceData && <ConfirmViewConfig 
          tourConfirmData={tourConfirmData}
          priceData={priceData}
          responsiveData={responsiveData}
          isPrivate={data.private}
          bookingData={bookingData}
          menuData={menuData}
          setMenuData={setMenuData}
          upsellsData={upsellsData}
          setUpsellsData={setUpsellsData}
          occupancyData={occupancyData}
          setOccupancyData={setOccupancyData}
          callNext={() => setConfirmView(loggedIn ?  'payment' : 'login')}
        />}

        {confirmView === 'login' && priceData && <ConfirmViewLogin
          tourConfirmData={tourConfirmData}
          priceData={priceData}
          responsiveData={responsiveData}
          isPrivate={data.private}
          bookingData={bookingData}
          setConfirmView={setConfirmView}
          refreshLoggedIn={refreshLoggedIn}
        />}

        {confirmView === 'payment' && priceData && <ConfirmViewPayment
          tourConfirmData={tourConfirmData}
          priceData={priceData}
          responsiveData={responsiveData}
          isPrivate={data.private}
          bookingData={bookingData}
          setConfirmView={setConfirmView}
        />}
      </>}

      <LoadingCover loading={isLoading} />
    </div>
  )
}

export default BookConfirm