import ITourBookingData from "../types/ITourBookingData"
import ITourConfirmData from "../types/ITourConfirmData"
// import { getAbsoluteNow } from "./time"
import moment from 'moment'


export type PriceDataOccupancy = {
    adultsNumber: number
    adultsPrice: number
    adultsTotal: number

    childrenNumber: number
    childrenPrice: number
    childrenDiscountPercent?: number
    childrenTotal: number

    infantsNumber: number
    infantsPrice: number
    infantsDiscountPercent?: number
    infantsTotal: number

    total: number
}


export type PriceDataMenu = {
    items: {
        id: string
        name: string
        price: number
        count: number
        total: number
    }[]
    total: number
}

export type PriceDataUpsells = {
    items: {
        id: string
        name: string
        price: number
        count: number
        total: number
    }[]
    total: number
}

export type PriceData = {
    basePrice: number
    baseDiscountPrice: number
    baseDiscountPercent?: number
    baseDiscountReason: string[]
    
    occupancy: PriceDataOccupancy
    menu: PriceDataMenu
    upsells: PriceDataUpsells
    total: number
}

export const roundPrice = (v: number) => {
    return Math.round(v*100)/100
}

export const calculatePrice = (tourConfirmData: ITourConfirmData, tourBookingData: ITourBookingData): PriceData => {
    let basePrice = tourConfirmData.price
    const tourIsPrivate = tourConfirmData.private || tourBookingData.bookedPrivately

    const price: Pick<PriceData, 'basePrice' | 'baseDiscountPrice' | 'baseDiscountPercent' | 'baseDiscountReason'> = {
        basePrice: basePrice,
        baseDiscountPrice: basePrice,
        baseDiscountReason: []
    }

    const occupancy: PriceDataOccupancy = {
        adultsNumber: 0,
        adultsPrice: 0,
        adultsTotal: 0,
        childrenNumber: 0,
        childrenPrice: 0,
        childrenTotal: 0,
        infantsNumber: 0,
        infantsPrice: 0,
        infantsTotal: 0,
        total: 0
    }


    // DISCOUNTS!
    const discountsApplying:number[] = []

    // Occupancy discount
    let occupancyDiscount = 0
    let occupancyThreshold = 0
    if(tourConfirmData.discountAmount?.length > 0 && !tourIsPrivate) {
        const nrPeople = tourBookingData.occupancy.infants + tourBookingData.occupancy.children + tourBookingData.occupancy.adults

        for (const d of tourConfirmData.discountAmount) {
            if ( nrPeople >= d.threshold && d.discount > occupancyDiscount) {
                occupancyDiscount = d.discount
                occupancyThreshold = d.threshold
            }
        }
    }

    if(occupancyDiscount > 0) {
        discountsApplying.push(occupancyDiscount)
        price.baseDiscountReason.push(`${occupancyThreshold} or more people discount`)
    }


    // Booked Privately discount
    if(tourBookingData.bookedPrivately && tourConfirmData.privatePossible) {
        discountsApplying.push(tourConfirmData.privatePossibleDiscount)
        price.baseDiscountReason.push(`Private booking discount ${tourConfirmData.privatePossibleDiscount}%`)
    }


    if(tourConfirmData.discountEarlyBird?.length > 0 || tourConfirmData.discountLastMinute?.length > 0) {
        const diff = moment.duration(moment.unix(tourBookingData.startTime).diff(tourBookingData.now))
        const diffDays = diff.asDays()
        const diffHours = diff.asHours()

        // Early bird discount
        if(tourConfirmData.discountEarlyBird?.length > 0) {
            let discount = 0

            for (const d of tourConfirmData.discountEarlyBird) {
                if (d.threshold < diffDays && d.discount > discount) {
                    discount = d.discount
                }
            }

            if(discount > 0) {
                discountsApplying.push(discount)
                price.baseDiscountReason.push(`Early bird discount`)
            }
        }

        // Last minute discount
        if(tourConfirmData.discountLastMinute?.length > 0) {
            let discount = 0

            for (const d of tourConfirmData.discountLastMinute) {
                if (d.threshold > diffHours && d.discount > discount) {
                    discount = d.discount
                }
            }

            if(discount > 0) {
                discountsApplying.push(discount)
                price.baseDiscountReason.push(`Last minute discount`)

            }
        }
    }

    
    if(tourBookingData.occupancy.adults > 0) {
        occupancy.adultsNumber = tourBookingData.occupancy.adults
        occupancy.adultsPrice = tourIsPrivate ? 0 : basePrice
        occupancy.adultsTotal = tourIsPrivate ? 0 : roundPrice(basePrice * tourBookingData.occupancy.adults)
    }

    if(tourBookingData.occupancy.children > 0) {
        occupancy.childrenNumber = tourBookingData.occupancy.children
        occupancy.childrenDiscountPercent = tourConfirmData.childrenDiscount
        const percentMultiplier = (100 - tourConfirmData.childrenDiscount) / 100
        occupancy.childrenPrice = tourIsPrivate ? 0 : roundPrice(basePrice * percentMultiplier)
        occupancy.childrenTotal = tourIsPrivate ? 0 : roundPrice(occupancy.childrenPrice * tourBookingData.occupancy.children)
    }

    if(tourBookingData.occupancy.infants > 0) {
        occupancy.infantsNumber = tourBookingData.occupancy.infants
        occupancy.infantsDiscountPercent = tourConfirmData.infantsDiscount
        const percentMultiplier = (100 - tourConfirmData.infantsDiscount) / 100
        occupancy.infantsPrice = tourIsPrivate ? 0 : roundPrice(basePrice * percentMultiplier)
        occupancy.infantsTotal = tourIsPrivate ? 0 : roundPrice(occupancy.infantsPrice * tourBookingData.occupancy.infants)
    }

    occupancy.total = roundPrice(occupancy.adultsTotal + occupancy.childrenTotal + occupancy.infantsTotal)

    // MENU
    let menu:PriceDataMenu = {
        items: [],
        total: 0
    }

    if(tourConfirmData.menu) {
        for(const menuItem of tourBookingData.menu) {
            const item = {
                id: menuItem.id,
                name: menuItem.name,
                count: menuItem.count,
                price: menuItem.price ? parseFloat(menuItem.price as unknown as any) : 0,
                total: 0
            }
            
            if (item.price > 0 && !tourConfirmData.menu.included_in_price) {
                item.total = item.price * menuItem.count
                menu.total = menu.total + item.total
            }

            menu.items.push(item)
        }
    }

    // UPSELLS
    let upsells: PriceDataUpsells = {
        items: [],
        total: 0
    }

    if(tourConfirmData.upsells?.length) {
        for(const upsell of tourBookingData.upsells) {
            const item = {
                id: upsell.id,
                name: upsell.name,
                count: upsell.count,
                price: upsell.price ? parseFloat(upsell.price as unknown as any) : 0,
                total: 0
            }

            // console.log('menuItem', menuItem, item)
            
            if (item.price) {
                item.total = item.price * upsell.count
                upsells.total = upsells.total + item.total
            }

            upsells.items.push(item)
        }
    }

    // Tickets price
    let ticketsPrice = 0
    if (tourBookingData.bookedPrivately) {
        ticketsPrice = roundPrice(tourConfirmData.seatsCapacity * basePrice) 
    } else if(tourConfirmData.private) {
        ticketsPrice = roundPrice(basePrice) 
    }else {
        ticketsPrice = occupancy.total
    }
    
    // Discount
    const totalDiscount = discountsApplying.map((d: any) => parseFloat(d)).reduce((acc, v) => acc + v, 0)
    price.baseDiscountPercent = totalDiscount

    const totalDiscountMultiplier = (100 - totalDiscount) / 100
    price.baseDiscountPrice = roundPrice(totalDiscountMultiplier * ticketsPrice)

    let total = menu.total + upsells.total + price.baseDiscountPrice

    const res: PriceData = Object.assign(price, {
        occupancy,
        menu,
        upsells,
        total,
    })

    return res
}


export const calculateSeats = (tourBookingData: ITourBookingData): number => {
    let res = 0
    
    if(tourBookingData.occupancy.adults > 0) {
        res += tourBookingData.occupancy.adults
    }
    if(tourBookingData.occupancy.children > 0) {
        res += tourBookingData.occupancy.children
    }
    if(tourBookingData.occupancy.infants > 0) {
        res += tourBookingData.occupancy.infants
    }
    
    return res
}