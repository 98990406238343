import React, { useMemo, useState } from "react"
import "./tour-card.scss"
import { getFavourites, toggleFavourite } from '../../services/favourites'
import ITourCardData from "../../shared/types/ITourCardData"
import { getTourTypeTranslation } from "../../shared/utils/tourType"
import ImageCard from "../imageCard/ImageCard"
import TitleBlock from "../titleBlock/TitleBlock"
import { useAuthModalContext } from "../../providers/Auth"


export default function TourCard(tourCard: ITourCardData & { url?: string, upsells?: any[], favourite?: boolean } & { hideFavouriteIcon?: boolean, cardWindowWidthPercent? : number, lazyLoad?: boolean }) {
  const authContext = useAuthModalContext()
  const [favouriteRefresh, setFavouriteRefresh] = useState(0)
  const [favouriteMessage, setFavouriteMessage] = useState<string | null>(null)

  const favourite = useMemo(() => {
    const f = getFavourites()
    return f.includes(tourCard.id)
  }, [tourCard, favouriteRefresh])


  const onFavouriteClick = async (e:any, id: number) => {
    e.preventDefault()
    if(!authContext.isLoggedIn(null)) {
      authContext.showAuth("Please log in or register", "login", undefined)
      return
    }

    await toggleFavourite(id)
    setFavouriteRefresh(favouriteRefresh+1)
    setFavouriteMessage(favourite ? "Removed from wishlist" :  "Added to wishlist")
    setTimeout(() => setFavouriteMessage(null), 1500)
  }
  
  return <div className="card card--tour ">
    <a
      href={tourCard.url || "#"}
      className=""
      style={{cursor: tourCard.url ? 'pointer' : 'inherit'}}
      rel="noopener noreferrer"
    >
      <div className="card__image">
        <ImageCard images={tourCard.coverImages} windowWidthPercent={tourCard.cardWindowWidthPercent || 30} lazyLoad={tourCard.lazyLoad}/>
        <div className="card__image__content">
          {!tourCard.hideFavouriteIcon && <i className={"icon icon--heart " + (favourite ? ' icon--heart--active' : '')} onClick={(e) => onFavouriteClick(e, tourCard.id)}>
            <span className="icon__popup icon__popup--bottom-left">{favouriteMessage ? favouriteMessage : (favourite ? 'Remove from Wishlist' : 'Add to Wishlist')}</span>
          </i>}

          <TitleBlock 
            title={tourCard.name}
            supTitle={getTourTypeTranslation(tourCard.tourType)}
            stars={tourCard.stars}
            votes={tourCard.votes}
            text={(tourCard.baseDuration > 0 ? (tourCard.baseDuration > 24 ? `${Math.round(tourCard.baseDuration/24)} days` : `${tourCard.baseDuration} hours`) : '')}
            basePrice={tourCard.basePrice}
            isPrivate={tourCard.private}
          />

          {/* <div className="card__price-tag">
            <small>from</small>
            <h3>{tourCard.basePrice}€</h3>
            <span>{tourCard.private ? `per group` : `per person`}</span>
          </div> */}
        </div>
      </div>
      {!tourCard.published && <div className="card__image__content__unpublished">
        <div className="unpublished-ribbon">
          <span>Unpublished</span>
        </div>
      </div>}
    </a>
  </div>
}
