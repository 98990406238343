import React, { useEffect, useState } from "react"
import {
  PaymentElement,
  useStripe,
  useElements
} from "@stripe/react-stripe-js"
import { StripePaymentElementOptions } from "@stripe/stripe-js"
import { payBooking } from '../../../services/api'
import ITourBookingData from "@src/shared/types/ITourBookingData"
import { LoadingDiv } from "../../loaders/Loaders"

const CheckoutFormLoadingDivMessages = [
  'Processing the payment. Do not close this window.',
  'Checking the payment details with your credit card provider.',
  'Almost there! Thank you for your patience '
]

const CheckoutFormLoadingDiv = () => {
  const [msgIdx, setMsgIdx] = useState(0)

  useEffect(() => {
    setTimeout(() => {
      (msgIdx < CheckoutFormLoadingDivMessages.length - 1) && setMsgIdx(msgIdx+1)
    }, 5000)
  }, [msgIdx])

  return <div className="checkout-form__loading-div">
    <p>{CheckoutFormLoadingDivMessages[msgIdx]}</p>
    <LoadingDiv loading={true} />
  </div>
}

export default function CheckoutForm({booking, user, isLoading, setIsLoading}: {booking: ITourBookingData, user: {name: string, surname: string, email: string, stripeCustomerId: string}, isLoading: boolean, setIsLoading: (v:boolean) => void}) {
  const stripe = useStripe()
  const elements = useElements()

  const [message, setMessage] = useState<null | string>(null)

  const handleSuccess = async (payResponse: any) => {
    setMessage(`Payment success`)

    if(payResponse?.data?.bookingId) {
      window.location.assign(`/site/bookings/${payResponse.data.bookingId}/?paymentIntentCheck=true`)
    } else {
      window.location.assign('/site/bookings/fail')
    }
  }

  const handleSubmit = async (e: any) => {
    e.preventDefault()

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return
    }

    setIsLoading(true)

    // await new Promise((resolve, reject) => setTimeout(() => resolve(true), 10000))
    // setIsLoading(false)
    // return
    
    const resElements = await elements.submit();
    if (resElements.error) {
      setMessage(resElements.error.message || "Form invalid")
      setIsLoading(false)
      return;
    }

    // Create the PaymentMethod using the details collected by the Payment Element
    const resPaymentMethod = await stripe.createPaymentMethod({
      elements,
      params: {
        billing_details: {
          name: `${user.name} ${user.surname}`,
          email: user.email,
        }
      }
    })

    if (resPaymentMethod.error) {
      setMessage(resPaymentMethod.error.message || "Error creating payment method")
      setIsLoading(false)
      return
    }

    let payResponse
    try {
      payResponse = await payBooking(booking.id as number, resPaymentMethod.paymentMethod.id)
    } catch(e) {
      console.log(e)
      setMessage("Error making a payment")
      setIsLoading(false)
    }

    if (payResponse?.data.error) {
      setMessage(`Error making a payment. ${payResponse?.data.error}`)
      setIsLoading(false)
    } else if (payResponse?.data.status === "requires_action") {
      const {
        error,
        paymentIntent
      } = await stripe.handleNextAction({
        clientSecret: payResponse?.data.clientSecret
      })
  
      if (error) {
        setMessage(`Error making a payment. ${error}`)
        setIsLoading(false)
        // Show error from Stripe.js in payment form
      } else {
        handleSuccess(payResponse)
      }
    } else {
      handleSuccess(payResponse)
    }
  }

  const paymentElementOptions = {
    layout: "tabs",
    wallets: {
      applePay: 'never',
      googlePay: 'never'
    }
  } as StripePaymentElementOptions

  return (
    <div className="checkout-form">
        <form id="payment-form" onSubmit={handleSubmit}>
            <PaymentElement id="payment-element" options={paymentElementOptions} />
            <button disabled={isLoading || !stripe || !elements} id="submit">
                <span id="button-text">
                    {isLoading ? <div className="spinner" id="spinner"></div> : "Pay now"}
                </span>
            </button>
            {/* Show any error or success messages */}
            {message && <div id="payment-message">{message}</div>}

            {isLoading && <CheckoutFormLoadingDiv />}
        </form>
    </div>
  )
}