import { isBrowser } from '../services/browser'
import React, { useLayoutEffect, useState } from 'react'

export const MOBILE_BREAKPOINT = 768
export const TABLET_BREAKPOINT = 1366
export const DESKTOP_BREAKPOINT = 1700


export interface IResponsiveData {
    isMobile: boolean
    isTablet: boolean
    isDesktop: boolean
    isLargeDesktop: boolean
    w: number,
    h: number,
}

const getResponsiveData = (w: number, h: number): IResponsiveData => ({
    isMobile: w <= MOBILE_BREAKPOINT,
    isTablet: w > MOBILE_BREAKPOINT && w <= TABLET_BREAKPOINT,
    isDesktop: w > TABLET_BREAKPOINT && w <= DESKTOP_BREAKPOINT,
    isLargeDesktop: w > DESKTOP_BREAKPOINT,
    w: w,
    h: h,
})

export const useResponsiveData = () => {
  const w = isBrowser() ? window.innerWidth : 1300
  const h = isBrowser() ? window.innerHeight : 700
  const [size, setSize] = useState(getResponsiveData(w, h))

  useLayoutEffect(() => {
    function updateSize() {
      setSize(getResponsiveData(window.innerWidth, window.innerHeight))
    }

    window.addEventListener('resize', updateSize)
    updateSize()
    return () => window.removeEventListener('resize', updateSize)
  }, [])
  return size
}


export const useScrollTop = () => {
  const [scrollTop, setScrollTop] = useState(0);

  useLayoutEffect(() => {
    function updateScrollTop() {
        // window.innerWidth, window.innerHeight
        setScrollTop(window.scrollY)
    }

    window.addEventListener('scroll', updateScrollTop)
    updateScrollTop()
    return () => window.removeEventListener('scroll', updateScrollTop)
  }, [])
  return scrollTop
}
  
export const triggerWindowScroll = () => {
  window.scrollTo(window.scrollX, window.scrollY-1)
  setTimeout(() => { window.scrollTo(window.scrollX, window.scrollY+1) }, 100)
}