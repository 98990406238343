import * as React from "react"
import { BlockText } from '../blockText/BlockText'
import Stars from '../stars/Stars'
import "./title-block.scss"


const TitleBlock = ({title, supTitle, stars, votes, text, basePrice, isPrivate=false, big=false, huge=false}: any) => {
  return (<div className={"title-block" + (big ? " title-block--big": "") + (huge ? " title-block--huge": "")}>
      <div className="title-block__header">
        {supTitle && <div className="title-block__header__suptitle">{supTitle}</div>}

        <div className="title-block__header__stars-text-wrapper">
          {stars > 0 && <div className="title-block__header__stars"><Stars stars={stars} votes={votes} /></div>}
          {text?.length > 0 && <div className="title-block__header__text">{text} {isPrivate ? ' • private' : ''}</div>}
        </div>
        {/* {basePrice > 0 && <p>From {basePrice}€ </p>} */}
      </div>
      <h3 className="title-block__title">
        {basePrice > 0 && <span className="title-block__title__price">
          <small>from</small>
          <b>{basePrice}€ </b>
          <div>{isPrivate ? `per group` : `per person`}</div>
          <i>&bull;</i>
        </span>}

        <BlockText text={title} />

      </h3>
  </div>)
}

export default TitleBlock