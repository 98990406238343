import React, { useCallback, useState } from "react"
import { Formik, Form, Field, ErrorMessage } from 'formik'
import { validateEmail } from '../../../services/validate'
import { LoadingCover } from '../../loaders'
import IFormParams from "../../../types/IFormParams"
import ILoginData from '../../../shared/types/ILoginData'
import validateILoginData from '../../../shared/validators/ILoginData.validator'
import getValidationErrors from "../../../utils/getValidationErrors"
import { resolveFormSubmit } from "../../../utils/form"
import _ from 'lodash'

const defaultParams:ILoginData = { 
  email: '', 
  password: '',
}

export default function LoginForm(args: IFormParams) {
 return (
  <Formik
       initialValues={args.params || _.cloneDeep(defaultParams)}
       validate={values => {
        return getValidationErrors(values, validateILoginData)
       }}
       onSubmit={async (values, { setSubmitting }) => {
          await resolveFormSubmit(values, args)
          setSubmitting(false)
       }}
     >
       {({ isSubmitting, isValid }) => (
          <Form className="form">
            <div className="form__row">
              <label>Email <ErrorMessage className="form__row__error" name="email" component="i" /></label>
              <Field type="email" name="email" />
            </div>

            <div className="form__row">
              <label>Password <ErrorMessage className="form__row__error" name="password" component="i" /></label>
              <Field type="password" name="password" />
            </div>
            <button className="button" type="submit" aria-label="Login">
              {args.options.submitBtnTxt || "Login"}
            </button>
            
            <LoadingCover loading={isSubmitting} />
          </Form>
       )}
     </Formik>
 )
}
