import React, { useState, useEffect} from 'react'
import LoginForm from "../../../components/auth/loginForm/LoginForm"
import RegisterForm from "../../../components/auth/registerForm/RegisterForm"
import ForgotPasswordForm from "../../../components/auth/forgotPasswordForm/ForgotPasswordForm"
import * as api from '../../../services/api'
import config from '../../../services/config'
import { getCaptchaToken } from '../../../services/validate'
import { refreshFavourites } from '../../../services/favourites'
import { setToken } from '../../../services/auth'
import IAPIFrontResponse from "../../../types/IAPIFrontResponse"
import ILoginData from "../../../shared/types/ILoginData"
import IRegisterData from "../../../shared/types/IRegisterData"
import { FormResponseMsgSuccess, FormResponseMsgError } from '../../../components/form/formResponse/FormResponse'


declare global {
    var grecaptcha: any;
}


const afterLogin = (callback: string|null, role: string|null) => {
  if(callback) {
    return window.location.replace(callback)
  }

  if(role === 'OPERATOR') {
    return window.location.replace('/operator')
  }
  
  return window.location.reload()
}

  
export const LoginWidget = (props: any) => {
    const [activeForm, setActiveForm] = useState<string>(props.view)
    const [successMsg, setSuccessMsg] = useState<string | null>(null)
    const [errorMsg, setErrorMsg] = useState<string | null>(null)
  
    useEffect(() => {
      let timeout = 0
      let google = (window as any).google     
      if(!google) {
        timeout = 3000
      }

      setTimeout(() => {
        google = (window as any).google     
        google.accounts.id.initialize({
          client_id: config.googleAuthClientId,
          use_fedcm_for_prompt: false,
    
          callback: async(data: any) => { 
            try {
              data.captcha = await getCaptchaToken()
              const res = await api.googleAuth(data)
              if(!res.data) {
                throw new Error('Something went wrong')
              }
              
              setToken(res.data)
              setSuccessMsg('Success')
              await refreshFavourites()
    
              props.afterLogin && props.afterLogin(res.data)
              !props.afterLogin && setTimeout(() => {
                afterLogin(props.callback, res.data.user.role)
              }, 100)
            } catch (e) {
              setErrorMsg(String(e))
            }
          }
        })
        const parent = document.getElementById('google_btn')
        google.accounts.id.renderButton(parent, {theme: "filled_blue"})
        google.accounts.id.prompt()
      }, timeout)
    }, [])
  
    const login = async (data: ILoginData) => {
      setSuccessMsg(null)
      setErrorMsg(null)
      data.captcha = await getCaptchaToken()
      const res = await api.login(data)
      return res
    }
  
    const register = async (data: IRegisterData) => {
      setSuccessMsg(null)
      setErrorMsg(null)
      data.captcha = await getCaptchaToken()
      const res = await api.register(data)
      setToken(res.data)
      return res
    }

    const forgotPassword = async (data: IRegisterData) => {
      setSuccessMsg(null)
      setErrorMsg(null)
      data.captcha = await getCaptchaToken()
      const res = await api.forgotPassword(data)
      console.log('res', res)
      // setToken(res.data)
      return res
    }
  
    const success = async (data: any, resp: IAPIFrontResponse) => {
      setSuccessMsg('Success')
      if(activeForm === 'forgotPassword') {
        setTimeout(() => {
          afterLogin(props.callback, null)
        }, 100)
        return
      }

      setToken(resp.data)
      await refreshFavourites()

      props.afterLogin && props.afterLogin(resp.data) 
      !props.afterLogin && setTimeout(() => {
        afterLogin(props.callback, resp.data.user.role)
      }, 100)
    }
  
    const error = async (data: any, resp: IAPIFrontResponse) => {
      resp.message && setErrorMsg(resp.message)
    }
  
    return <div className="login-widget">
      <div className="form-wrapper">
        {!props.hideMessage && <h1>{props.message || 'Welcome to Tour Boats'}</h1>}
  
        <FormResponseMsgSuccess message={successMsg} />
        <FormResponseMsgError message={errorMsg} />
  
        { activeForm == 'login' && <LoginForm onSubmit={login} onSuccess={success} onError={error} options={{submitBtnTxt: props.loginBtnTxt || null}} /> }
        { activeForm == 'register' && <RegisterForm onSubmit={register} onSuccess={success} onError={error}  options={{submitBtnTxt: props.registerBtnTxt || null}}/> }
        { activeForm == 'forgotPassword' && <ForgotPasswordForm onSubmit={forgotPassword} onSuccess={success} onError={error}  options={{submitBtnTxt: props.forgotPasswordBtnTxt || null}}/>}
        <div className="separator"><span>or</span></div>
  
        <a className="login-widget__register-btn" onClick={() => setActiveForm(activeForm == 'login' ? 'register' : 'login')}>{activeForm == 'login' ? 'Register' : props.backToLoginText || 'Back to Login'}</a>
        
        <div id="google_btn"></div>

        <a className="login-widget__forgot-password-btn" onClick={() => setActiveForm('forgotPassword')}>Forgot Password</a>
        <small>By logging in or registering you confirm that you have read and agree with our <a className="link" target="_blank" href="/site/legal/tac">terms & conditions</a>.</small>
      </div>
    </div>
  }