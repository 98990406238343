import * as React from "react"
import { useCallback, useEffect, useState } from "react"
import '@hassanmojab/react-modern-calendar-datepicker/lib/DatePicker.css'
import { Calendar, utils } from '@hassanmojab/react-modern-calendar-datepicker'

import Sticky from 'react-sticky-el'
import ITourAvailabilityData from '../../../shared/types/ITourAvailabilityData'
import { useResponsiveData } from "../../../utils/responsive"
import { getTourAvailability } from "../../../services/api"
import { getDateNiceFormatting } from "../../../services/date"
import { getAbsoluteNow, getDateFromUnix, getTimeFromUnix } from '../../../shared/utils/time'
import { useCustomRequestModalContext } from '../../../providers/CustomRequestModal'
import { LoadingCover } from '../../loaders'
import "./book-dates-detailed.scss"
import moment from "moment"


const CustomRequestWidget = ({onButtonClick}: any) => {
  return <div className="custom-request-widget">
    <h5>Not finding what you are looking for?</h5>
    <p>- Do the start and end times not suit you?</p>
    <p>- Do you need something else, not stated in the offering?</p>
    <p>- Would you like to organize a custom tailored tour?</p>
    <button className="button button--invert button--big" onClick={() => onButtonClick()}  aria-label="Send custom request">
      <h2>Send a Custom Request</h2>  
      <p>Contact the operator to tailor your own custom experience</p>
    </button>
  </div>
}

// TODO: type this
const BookDatesDetailed = ({data, selectTime, minSeats=0}: {data: any, selectTime: any, minSeats?: number}) => {
  const [isLoading, setIsLoading] = useState(false)
  const [dates, setDates] = useState<ITourAvailabilityData[]>([])
  const [after, setAfter] = useState<number>(data.after || getAbsoluteNow())
  const [calendarDate, setCalendarDate] = useState(null) 
  const [showMobileCalendar, setShowMobileCalendar] = useState(false)
  
  const responsiveData = useResponsiveData()
  const customRequestModalContext = useCustomRequestModalContext()  

  const setCalendarDateFromDate = async(v: any) => {
    setCalendarDate(v)
    const a = moment().utc(false).year(v.year).month(v.month-1).date(v.day).set({hour: 0, minute: 0, second:0, millisecond:0}).unix()
    setAfter(a)
    setDates([])
  }

  const getDates = useCallback(async () => {
    setIsLoading(true)
    const res = await getTourAvailability(data.tourId, after, 8)
    setDates([...dates, ...res.data])
    setIsLoading(false)
  }, [data.tourId, after])

  const getNext = () => {
    if (dates.length) {
      setAfter(dates[dates.length-1].endTime)
    } else {
      setAfter(getAbsoluteNow())
    }
  }

  useEffect(() => {
    getDates()
  }, [after])

  const topOffset = 100

  return (
    <div className="tour-book-dates-detailed">

      <div className="tour-book-dates-detailed__header">

      </div>

      <div className="tour-book-dates-detailed__content">
        <div className="tour-book-dates-detailed__controls">
          {!responsiveData.isMobile && <Sticky topOffset={-topOffset} stickyStyle={{top: topOffset+"px"}}>
            <h3>Show tours starting from:</h3>

            {/* TODO: Fix calendar going one day in advance */}
            <Calendar
              value={calendarDate}
              onChange={(value) => setCalendarDateFromDate(value)}
              shouldHighlightWeekends
              minimumDate={utils('en').getToday()}
            />
          </Sticky>}

          {responsiveData.isMobile && <>
            <button className="button button--minimalistic" onClick={() => setShowMobileCalendar(!showMobileCalendar)}  aria-label="Show calendar"> {showMobileCalendar ? 'Hide' : 'Show'} calendar</button>
            {showMobileCalendar && <Calendar
              value={calendarDate}
              onChange={(value) => setCalendarDateFromDate(value)}
              shouldHighlightWeekends
            />}
          </>}
        </div>

        <div className="tour-book-dates-detailed__dates">
          <ol>
            {dates.map((date: any, i: number) => {
              return <li key={i} className="tour-book-dates-detailed__date">
                <div className="tour-book-dates-detailed__date__header">
                    {(i == 0 || getDateFromUnix(dates[i-1].startTime) !== getDateFromUnix(date.startTime)) && <h3>{getDateFromUnix(date.startTime)}</h3>}
                </div>
                <div className="tour-book-dates-detailed__date__content">
                  <div className="tour-book-dates-detailed__date__details">
                    <h4>{getDateNiceFormatting(date.startTime, date.endTime, true, false)}</h4>
                    {!date.private && date.seatsAvailable == date.seatsCapacity && date.privatePossible && <a href="#" onClick={() => selectTime(date, true)}>Book for a private group</a>}
                    {!date.private && date.seatsAvailable < date.seatsCapacity && <span>Join {date.seats - date.seatsAvailable} other guests</span>}
                  </div>

                  <div className="tour-book-dates-detailed__date__price">
                    <span><b>€ {date.price}</b> {date.private ? "/ tour" : "/ person"}</span>
                    {date.seatsAvailable >= minSeats && <button className={"button "} onClick={() => selectTime(date, false)}  aria-label="Book">
                      <h2>Book</h2>  
                    </button>}
                    {date.seatsAvailable < minSeats && <p>Only {date.seatsAvailable} seats available</p>}
                  </div>
                </div>
              </li>
            })}
          </ol>

          <button className="button button--invert button--big button--load-more" onClick={getNext}  aria-label="Load more dates">
            <h2>Load more</h2>
            <p>Nothing that works is in the list? Check for more dates</p>
          </button>
          
          {responsiveData.isMobile && <CustomRequestWidget onButtonClick={() => customRequestModalContext.showModal({tourId: data.tourId, operatorId: null, message: ""})} />}
        </div>
      </div>

      <LoadingCover loading={isLoading} />
    </div>
  )
}

export default BookDatesDetailed