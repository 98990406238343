import { FieldProps } from "formik"
import React from "react"
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import './index.scss'


export const Phone = ({
  field,
  form
}: FieldProps) => {
  const onChange = (value: any) => {
    form.setFieldValue(
      field.name,
      value
    )
  }

  return (
    <PhoneInput
      placeholder={"Enter phone number"}
      value={field.value}
      onChange={onChange}
      defaultCountry="HR"
    />
  )
}

export default Phone