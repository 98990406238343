// console.log('process.env', process.env)
// console.log('process.env.PAYMENT_RETURN_URL', process.env.PAYMENT_RETURN_URL)
// console.log('process.env.BACKEND_URL', process.env.BACKEND_URL)
// console.log('process.env.STRIPE_PK', process.env.STRIPE_PK)

const err = (v: string) => {
  throw new Error(`process.env variable ${v} not set`)
}

export default {
  stripePk: process.env.ENV_STR_STRIPE_PK  || err('ENV_STR_STRIPE_PK'),
  paymentReturnUrl: process.env.ENV_STR_PAYMENT_RETURN_URL || err('ENV_STR_PAYMENT_RETURN_URL'),
  backendUrl: process.env.ENV_STR_BACKEND_URL || err('ENV_STR_BACKEND_URL'),
  googleAuthClientId: process.env.ENV_STR_GOOGLE_AUTH_CLIENT_ID || err('ENV_STR_GOOGLE_AUTH_CLIENT_ID'),
}