import React, { useState, useContext } from 'react'
import Modal from 'react-bootstrap/Modal'
import BookDatesDetailed from "../components/tour/bookDatesDetailed/BookDatesDetailed"
import BookConfirm from "../components/tour/bookConfirm/BookConfirm"
import { triggerWindowScroll } from '..//utils/responsive'
export const TourBookingModalContext = React.createContext({})
export const useTourBookingModalContext = () => useContext(TourBookingModalContext) as {showModal: (
    data: any, 
    view: string,
  ) => Promise<void>
}


export const TourBookingModalContextProvider = (props: any) => {
  const [showModal, setShowModal] = useState(false)
  const [data, setData] = useState<any>(null)
  const [view, setView] = useState<any>(null)

  const handleShow = (oldData: any, oldView: string = 'dates') => {
    setData(oldData)
    setView(oldView)
    setShowModal(true)
  }

  const selectTime = (time: any, bookPrivately: boolean) => {
    data.time = time
    data.bookPrivately = bookPrivately
    setData({...data})
    setView('confirm')
  }

  return (
    <TourBookingModalContext.Provider value={{showModal: handleShow}}>
      {props.children}

      {/* onHide={() => { setShowModal(false); triggerWindowScroll()}}  */}
      <Modal show={showModal} fullscreen={true} dialogClassName="modal-xl modal-dialog modal-dialog--booking-dialog modal-dialog--fullscreen">
        <Modal.Body>
          <i className="modal-body__close" onClick={() => { setShowModal(false); triggerWindowScroll(); } } >x</i>
          {view === 'dates' && <BookDatesDetailed data={data} selectTime={selectTime} /> }
          {view === 'confirm' && <BookConfirm data={data} /> }
        </Modal.Body>
      </Modal>
    </TourBookingModalContext.Provider>
  )
}
