export const scrollToFirstError = () => {
    // console.log('scrollToFirstError', document.querySelector(".form__row__error") )
    let el = document.querySelector(".form__row__error")
    if (!el) el = document.querySelector(".container")
    if (!el) return
    const y = el.getBoundingClientRect().top + window.pageYOffset - 120
    window.scrollTo({top: y, behavior: 'smooth'})

    // document.querySelector(".form__row__error")?.scrollIntoView({behavior: "smooth"})
}
