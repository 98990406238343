export const isBrowser = () => typeof window !== "undefined"


export const updateQueryStringParameter = (uri: string, key: string, value: any) => {
    var re = new RegExp("([?&])" + key + "=.*?(&|$)", "i");
    var separator = uri.indexOf('?') !== -1 ? "&" : "?";
    if (uri.match(re)) {
      return uri.replace(re, '$1' + key + "=" + value + '$2');
    }
    else {
      return uri + separator + key + "=" + value;
    }
  }

  export const scrollToElement = (elClass: string) => {
      let el = document.querySelector(`.${elClass}`)
      if (!el) return
      const y = el.getBoundingClientRect().top + window.pageYOffset - 120
      window.scrollTo({top: y, behavior: 'smooth'})
  }