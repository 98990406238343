import ICustomCountConfig from '../types/ICustomCountConfig'
import ITourBookingOccupancy from '../types/ITourBookingOccupancy'
import ITourMenuData from '../types/ITourMenuData'

export const getCustomCountConfigErrors = (config: ICustomCountConfig[], minItems: number, maxItems: number) => {
  const total = config.reduce((acc:number, item: ICustomCountConfig) => acc + item.count, 0)
  if (total > maxItems) return `Total of all items (${total}) cannot be greater than ${maxItems}`
  if (total < minItems) return `Total of all items (${total}) cannot be les than ${minItems}`
  return null
}

export const getCustomCountConfigFromUpsells = (upsells: any[]): ICustomCountConfig[] => {
  const res: ICustomCountConfig[] = []
  for(const upsell of upsells) {
    let image = undefined 
    if(upsell.images.length > 0) {
      if(upsell.images[0]?.srcSet) {
        image = upsell.images[0].srcSet[0].uri
      }
    }

    const item = {
      id: upsell.id,
      name: upsell.name,
      description: upsell.description + (upsell.duration ? `Duration: ${upsell.duration} hours` : ''),
      image: image,
      icon: upsell.icon,
      count: 0,
      price: parseFloat(upsell.price),
      singular_item: upsell.singular_item,
      extraData: {
        duration: parseFloat(upsell.duration)
      }
    }

    res.push(item)
  }

  return res
}

export const getCustomCountConfigFromMenu = (menu: ITourMenuData, addEmpty=false): ICustomCountConfig[] => {
  const res: ICustomCountConfig[] = []

  for(const menuItem of menu.items) {
    let image = undefined 
    if(menuItem.images.length > 0) {
      if(menuItem.images[0]?.srcSet) {
        image = menuItem.images[0].srcSet[0].uri
      }
    }

    const item = {
      id: menuItem.id,
      name: menuItem.title,
      description: menuItem.description,
      image: image,
      count: 0,
      price: menu.included_in_price ? 0 : menuItem.price
    }

    res.push(item)
  }

  if(addEmpty && res.length > 0) {
    res.push({
      id: 'empty',
      name: 'Empty',
      description: 'No thank you',
      image: undefined,
      count: 0,
      price: 0,
    })
  }

  return res
}

const getCustomCountConfigItemFromOccupancy = (id: string, count: number, limit: number): ICustomCountConfig | null => {
  if(id === 'adults') {
    return {
      id,
      count,
      name: 'Adults',
      description: `Over the age of ${limit}`
    }
  }

  if(id === 'children') {
    return {
      id,
      count,
      name: 'Children',
      description: `Under the age of ${limit}`
    }
  }

  if(id === 'infants') {
    return {
      id,
      count,
      name: 'Infants',
      description: `Under the age of ${limit}`
    }
  }

  return null
}

export const getCustomCountConfigFromOccupancy = (occupancy: ITourBookingOccupancy, childrenMaxAge: number=18, infantsMaxAge: number=4): ICustomCountConfig[] => {
  const res:ICustomCountConfig[] = []
  const adults = getCustomCountConfigItemFromOccupancy('adults', occupancy.adults, childrenMaxAge)
  if (adults && occupancy.adults !== undefined) res.push(adults)
  const children = getCustomCountConfigItemFromOccupancy('children', occupancy.children, childrenMaxAge)
  if (children && occupancy.children !== undefined) res.push(children)
  const infants = getCustomCountConfigItemFromOccupancy('infants', occupancy.infants, infantsMaxAge)
  if (infants && occupancy.infants !== undefined) res.push(infants)


  return res
}



export const getOccupancyFromCustomCountConfig = (config: ICustomCountConfig[]):ITourBookingOccupancy  => {
  const res = {
    adults: 0,
    children: 0,
    infants: 0,
  }

  for (const c of config) {
    if (c.id === 'adults') res.adults = c.count
    if (c.id === 'children') res.children = c.count
    if (c.id === 'infants') res.infants = c.count
  }

  return res
}